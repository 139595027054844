import { api } from "api";
import {
  CheckOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { GenerateStatuses } from "./stateStepUtils";

const iconStyle = { paddingRight: "6px" };

export const PaymentState = GenerateStatuses({
  endpoint: api.payment,
  options: [
    {
      value: "draft",
      label: "Draft",
      icon: <FileTextOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "pending",
      label: "Pending",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
    },
    {
      value: "completed",
      label: "Completed",
      icon: <CheckOutlined style={{ ...iconStyle, color: "green" }} />,
    },
  ],
});
