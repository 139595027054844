import React, { useState, useLayoutEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { kmAxios } from "./api/api";
import { User } from "api";
import { useQuery } from "react-query";
import { REACT_APP_API } from "env";

export interface UseAuthReturn {
  token: string | null;
  user: User | null;
  login: (token: string, user: User) => void;
  logout: () => void;
  setToken: (token: string) => void;
  isMeLoading: boolean;
  hasAdminRole: boolean;
  hasUserRole: boolean;
}

export interface Props {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token") ?? null
  );

  const getCurrentUser = async (): Promise<any> => {
    const { data } = await axios.get(`${REACT_APP_API}/me`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data?.data;
  };

  const { data, isLoading } = useQuery(
    ["getCurrentUser", token],
    getCurrentUser,
    {
      enabled: !!token,
      onError: (error: any) => {
        if (error?.response?.status === 401) {
          setToken(null);
          localStorage.removeItem("token");
        }
      },
    }
  );
  const user: User | null = token ? data : null;

  useLayoutEffect(() => {
    // add authorization token to each request
    kmAxios.interceptors.request.clear();
    kmAxios.interceptors.request.use((config: any) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    });

    // todo: handle not authenticated redirect

    kmAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        return Promise.reject(error);
      }
    );
  }, [token]);

  const handleLogin = (token: string, user: User) => {
    localStorage.setItem("token", token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleSetToken = (token: string) => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  const value = {
    token,
    user,
    hasAdminRole: user?.roles?.some((x) => x.code === "admin") || false,
    hasUserRole: user?.roles?.some((x) => x.code === "user") || false,
    isMeLoading: isLoading,
    setToken: handleSetToken,
    login: handleLogin,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthContext = React.createContext<UseAuthReturn>(null!);

export const useAuthContext = () => React.useContext(AuthContext);
