import { Button, Popover, Space } from "antd";
import { ConfiguredSmartFilter, UseSmartFilters } from "./useSmartFilters";
import { CloseOutlined } from "@ant-design/icons";
import { SmartFilterDialog, getOperatorLabel } from "./SmartFilterDialog";
import { useState } from "react";

export interface ConfigureSmartFiltersProps {
  smartFilters: UseSmartFilters;
}

export const ConfigureSmartFilters = ({
  smartFilters,
}: ConfigureSmartFiltersProps) => {
  return (
    <Space style={{ marginBottom: "16px" }}>
      {smartFilters.appliedFilters
        .filter((x) => x.wasActivated)
        .map((filter) => (
          <SmartFilterComponent
            key={filter.key}
            configuredSmartFilter={filter}
            smartFilters={smartFilters}
          />
        ))}
    </Space>
  );
};

interface SmartFilterComponentProps {
  configuredSmartFilter: ConfiguredSmartFilter;
  smartFilters: UseSmartFilters;
}

const SmartFilterComponent = ({
  configuredSmartFilter,
  smartFilters,
}: SmartFilterComponentProps) => {
  const [open, setOpen] = useState<false | undefined>(undefined);
  const handleChangeOperand = (
    filter: ConfiguredSmartFilter,
    close: boolean
  ) => {
    smartFilters.applyFilter(filter);
    if (close) {
      setOpen(false);
      setTimeout(() => setOpen(undefined), 1);
    }
  };

  return (
    <Space.Compact size="small">
      <Button className="button-no-effects" disabled>
        {configuredSmartFilter.filter?.label}
      </Button>
      <Popover
        destroyTooltipOnHide
        trigger="click"
        open={open}
        content={
          <SmartFilterDialog
            modifyPart="operator"
            modelName={smartFilters.modelName}
            smartFilter={configuredSmartFilter}
            onChange={handleChangeOperand}
          />
        }
      >
        <Button>{getOperatorLabel(configuredSmartFilter.operator)}</Button>
      </Popover>
      <Popover
        destroyTooltipOnHide
        trigger="click"
        open={open}
        content={
          <SmartFilterDialog
            modifyPart="operand"
            modelName={smartFilters.modelName}
            smartFilter={configuredSmartFilter}
            onChange={handleChangeOperand}
          />
        }
      >
        <Button>
          {configuredSmartFilter.operand
            ? configuredSmartFilter.operand?.name
            : "-"}
        </Button>
      </Popover>
      <Button
        onClick={() => {
          smartFilters.removeFilter(configuredSmartFilter.filter?.field);
        }}
      >
        <CloseOutlined />
      </Button>
    </Space.Compact>
  );
};
