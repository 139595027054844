import { Button, Form, Modal } from "antd";
import { Auction, Bid, api } from "api";
import { Crud } from "hooks/useCrud";
import { useQuery, useQueryClient } from "react-query";

export interface Props {
  open: boolean;
  bid?: Bid;
  auction?: Auction;
  onClose: () => void;
}

export const AuctionBidWinConfirmModal: React.FC<Props> = ({
  open,
  bid,
  auction,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { save } = Crud.bid.useSave({
    onAfterSuccess: () => {
      queryClient.invalidateQueries([api.auction.key]);
      queryClient.invalidateQueries([api.task.key]);
      onClose();
    },
  });

  const handleSave = () => {
    if (!bid?.id) return null;
    const fd = new FormData();
    fd.set("id", bid.id);
    fd.set("state", "won");
    save(fd);
  };

  if (!auction || !bid) return null;
  return (
    <Modal
      title="Declare this bid as winner"
      open={open}
      onCancel={() => {
        onClose();
      }}
      footer={
        <>
          <Button
            type="primary"
            onClick={() => {
              handleSave();
            }}
          >
            Confirm
          </Button>
          <Button
            type="default"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </>
      }
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 852 }}
        autoComplete="off"
      >
        <Form.Item label="Price">
          {bid.price} {auction?.currency}
        </Form.Item>
        <Form.Item label="Comment">{bid.comment}</Form.Item>
      </Form>
    </Modal>
  );
};
