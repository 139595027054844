import { Card, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { User, api } from "api";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { UserTabAvatar } from "pages/Profile/UserTabAvatar";
import { useParams } from "react-router-dom";
import { UserTabSkill } from "./UserTabSkill";

export const UserEditPage = () => {
  const { userId: id } = useParams<{ userId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<User>({
    id,
    isEdit: !id,
    endpoint: api.user,
    onAfterCreateNavigateTo: (data) => `/platform/users/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/users",
  });

  const data = respData || {};

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? <>User</> : "Create user"}
          actions={
            <>
              {actions}
              {/* <ActivityLog id={id} modelMeta={bankAccountMeta} />
              <DeleteConfirmButton
                id={id}
                crud={api.carWork}
                onSuccessNavigateTo="/car-works"
              /> */}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Name"
              name={field("name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.name, <Input />)}
            </FormColInput>
            <FormColInput
              label="Email"
              name={field("email")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.email, <Input />)}
            </FormColInput>
            <FormColInput
              label="Bio"
              name={field("bio")}
              rules={[{ required: false }]}
            >
              {viewOrEdit(data.bio, <TextArea />)}
            </FormColInput>
            <FormColInput
              label="Status"
              name={field("state")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.state,
                <Select
                  options={[
                    { label: "Inactive", value: "inactive" },
                    { label: "Active", value: "active" },
                  ]}
                />
              )}
            </FormColInput>
          </MultiColumnForm>
        )}
        {id && <UserTabAvatar id={id} isMe={false} />}
        {id && <UserTabSkill id={id} isMe={false} />}
      </Card>
    </>
  );
};
