import { FilterOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { ConfiguredSmartFilter, UseSmartFilters } from "./useSmartFilters";
import { SmartFilterDialog } from "./SmartFilterDialog";
import { useState } from "react";

export const AddSmartFilterButton = ({
  appliedFilters,
  applyFilter,
  modelName,
  removeFilter,
}: UseSmartFilters) => {
  const [open, setOpen] = useState<false | undefined>();
  const [key, setKey] = useState<string>();
  const filter = appliedFilters.find((x) => x.key === key);
  const [modifyPart, setModifyPart] = useState<
    "field" | "operator" | "operand"
  >("field");

  const handleFilterChange = (
    smartFilter: ConfiguredSmartFilter,
    close: boolean
  ) => {
    setKey(smartFilter.filter?.field);
    applyFilter({ ...smartFilter, wasActivated: !!smartFilter.operand });
    if (!smartFilter.operator) {
      setModifyPart("operator");
    } else if (!smartFilter.operand) {
      setModifyPart("operand");
    }
    if (close) {
      setOpen(false);
      setTimeout(() => setOpen(undefined), 1);
    }
  };

  return (
    <Popover
      trigger="click"
      open={open}
      placement="bottomRight"
      onOpenChange={(visible) => {
        setKey(undefined);
        setModifyPart("field");
        if (!visible && !filter?.wasActivated) {
          removeFilter(filter?.key);
        }
      }}
      content={
        <SmartFilterDialog
          modifyPart={modifyPart}
          modelName={modelName}
          onChange={handleFilterChange}
          smartFilter={filter!}
          excludeFields={appliedFilters.map((x) => x.key)}
        />
      }
      destroyTooltipOnHide
    >
      <Button>
        <FilterOutlined />
      </Button>
    </Popover>
  );
};
