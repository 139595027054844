import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useAuthContext } from "AuthProvider";
import { Card, Col, Row, Space, Statistic, Typography } from "antd";
import { Stats, api } from "api";
import { useQuery } from "react-query";

export const Dashboard = () => {
  const { user } = useAuthContext();
  const { data, isLoading } = useQuery(
    ["getCurrentUserStats", user?.id],
    api.meStats.get,
    {
      enabled: !!user?.id,
    }
  );
  if (!data?.data) return null;
  const stat: Stats = data?.data;
  return (
    <Row style={{ maxWidth: "1200px" }} gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <Typography.Title level={2}>Bids</Typography.Title>
          <Row gutter={16}>
            <Col span={4}>
              <Statistic title="Total" value={stat.bids.total} precision={0} />
            </Col>
            <Col span={4}>
              <Statistic
                title="Active"
                value={stat.bids.active}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Canceled"
                value={stat.bids.canceled}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Disqualified"
                value={stat.bids.disqualified}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic title="Lost" value={stat.bids.lost} precision={0} />
            </Col>
            <Col span={4}>
              <Statistic title="Won" value={stat.bids.won} precision={0} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <Typography.Title level={2}>Tasks</Typography.Title>
          <Row gutter={16}>
            <Col span={4}>
              <Statistic title="Total" value={stat.tasks.total} precision={0} />
            </Col>
            <Col span={4}>
              <Statistic
                title="Completed"
                value={stat.tasks.completed}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Active"
                value={stat.tasks.active}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Planned"
                value={stat.tasks.planned}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Canceled"
                value={stat.tasks.canceled}
                precision={0}
              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Typography.Title level={2}>Files</Typography.Title>
          <Row gutter={16}>
            <Col span={4}>
              <Statistic title="Total" value={stat.files.total} precision={0} />
            </Col>
            <Col span={4}>
              <Statistic
                title="Accepted"
                value={stat.files.accepted}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Rejected"
                value={stat.files.rejected}
                precision={0}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Uploaded"
                value={stat.files.uploaded}
                precision={0}
              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Typography.Title level={2}>Payments</Typography.Title>
          <Row gutter={16}>
            <Col span={4}>
              <Statistic
                title="Total"
                value={stat.payments.total}
                precision={0}
                suffix={<span style={{ color: "grey" }}>transactions</span>}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Sum"
                value={stat.payments.total_sum}
                suffix="$"
                precision={0}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
