import { Button, Flex, Input, Space } from "antd";
import { Filter, FilterOption, ResponseBase, kmAxios } from "api";
import { useState } from "react";
import { ConfiguredSmartFilter } from "./useSmartFilters";
import { useQuery } from "react-query";
import { FilterStrictText } from "./Dialogs/FilterStrictText";
import { FilterText } from "./Dialogs/FilterText";
import { FilterDate } from "./Dialogs/FilterDate";
import { FilterRangePicker } from "./Dialogs/FilterRangePicker";

interface SmartFilterDialogProps {
  modifyPart: "field" | "operator" | "operand";
  smartFilter: ConfiguredSmartFilter;
  modelName: string;
  onChange?: (configuredFilter: ConfiguredSmartFilter, close: boolean) => void;
  excludeFields?: (string | undefined)[];
}

export const SmartFilterDialog = ({
  modifyPart,
  modelName,
  onChange,
  smartFilter,
  excludeFields = [],
}: SmartFilterDialogProps) => {
  const [searchFilter, setSearchFilter] = useState<string>();

  const { filters: allFilters } = useAvailableFilters(modelName, searchFilter);
  const filters = allFilters.filter((f) => !excludeFields.includes(f.field));

  const handleChangeFilter = (filter: Filter) => {
    onChange?.(
      {
        wasActivated: smartFilter?.wasActivated,
        key: filter.field,
        filter,
        // operator: filter.operators[0],
      },
      false
    );
  };

  const handleChangeOperator = (operator: string) => {
    onChange?.(
      {
        ...smartFilter,
        operator,
      },
      !!smartFilter.operand
      // false // todo: conditional
    );
  };

  const handleChangeOperand = (operand: FilterOption) => {
    onChange?.(
      {
        ...smartFilter,
        operand,
      },
      true
    );
  };

  const isStrictSelect = !smartFilter?.filter?.operators?.includes("like");

  const renderFilterOperand = () => {
    const props = {
      modelName,
      handleChangeOperand,
      smartFilter,
    };
    if (smartFilter.filter?.type === "date") {
      if (smartFilter.operator === "between") {
        return <FilterRangePicker {...props} />;
      }
      return <FilterDate {...props} />;
    }
    if (isStrictSelect) {
      return <FilterStrictText {...props} />;
    }
    return <FilterText {...props} />;
  };

  return (
    <div>
      {modifyPart === "field" && (
        <Flex vertical gap="small">
          <Input
            placeholder="Filter..."
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
          />
          <Flex vertical>
            {filters.map((filter) => (
              <Button
                type="text"
                key={filter.field}
                onClick={() => {
                  handleChangeFilter(filter);
                }}
                style={{ textAlign: "left" }}
              >
                {filter.label}
              </Button>
            ))}
          </Flex>
        </Flex>
      )}
      {modifyPart === "operator" && (
        <Flex vertical>
          {smartFilter.filter?.operators.map((operator) => (
            <Button
              key={operator}
              type="text"
              onClick={() => {
                handleChangeOperator(operator);
              }}
              style={{ textAlign: "left" }}
            >
              {getOperatorLabel(operator)}
            </Button>
          ))}
        </Flex>
      )}
      {modifyPart === "operand" && renderFilterOperand()}
    </div>
  );
};

const useAvailableFilters = (modelName: string, search: string | undefined) => {
  const { data } = useQuery(["filters", modelName, search], async () => {
    // todo: define type
    const { data } = await kmAxios.get<ResponseBase<any>>(`filters`, {
      params: {
        class: modelName,
        search: search,
        limit: 15,
      },
    });
    return data;
  });
  const filters: Filter[] = data?.data?.filters || [];
  return {
    filters,
  };
};

export const useAvailableFilterOptions = (
  modelName: string,
  field: string | undefined,
  search: string | undefined
) => {
  const { data } = useQuery(
    ["filterOptions", modelName, field, search],
    async () => {
      const { data } = await kmAxios.get<ResponseBase<any>>(`filter-options`, {
        params: {
          class: modelName,
          field: field,
          search: search,
          limit: 15,
        },
      });
      return data;
    },
    {
      enabled: !!modelName && !!field,
    }
  );
  const filterOptions: FilterOption[] = data?.data?.options || [];
  return {
    filterOptions,
  };
};

export const getOperatorLabel = (operator: string | undefined) => {
  switch (operator) {
    case "=":
      return "=";
    case "in":
      return "in";
    case "notin":
      return "not in";
    case "not":
      return "not";
    case "like":
      return "like";
    case "not like":
      return "not like";
    case "greater":
      return "greater";
    case "less":
      return "less";
    case "greaterorequal":
      return "greater or equal";
    case "lessorequal":
      return "less or equal";
    case "isnull":
      return "not set";
    case "notnull":
      return "is set";
    case "doesnthave":
      return "doesn't have";
    case "between":
      return "between";
    default:
      return operator;
  }
};
