import { Card, Form, Input } from "antd";
import { Payment, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { LinkToContract } from "components/Links/LinkToContract";
import { LpDateTimePicker } from "components/LpDateTimePicker";
import { PaymentState } from "components/State/PaymentState";
import { AjaxSelectContract } from "components/Select/AjaxSelectContract";

export const PaymentEditPage = () => {
  const { paymentId: id } = useParams<{ paymentId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Payment>({
    id,
    isEdit: !id,
    endpoint: api.payment,
    onAfterCreateNavigateTo: (data) => `/platform/payments/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/payments",
  });

  const data = respData || {};

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? "Payment" : "Create Payment"}
          actions={
            <>
              {data?.state === "draft" && actions}
              {id && <PaymentState.Selector id={id} state={data?.state} />}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            labelWrap={true}
            style={{ maxWidth: 1200 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Contract"
              name={field("contract_id")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                <LinkToContract {...data.contract} />,
                <AjaxSelectContract />
              )}
            </FormColInput>
            {data?.id && (
              <>
                <FormColInput label="User">{data.user?.name}</FormColInput>
              </>
            )}
            <FormColInput
              label="Amount"
              name={field("amount")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.amount, <Input />)}
            </FormColInput>
            {data?.id && (
              <FormColInput label="Currency">
                {data.contract?.currency}
              </FormColInput>
            )}
            <FormColInput
              label="Payment details"
              name={field("payment_details")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.payment_details, <Input />)}
            </FormColInput>
            <FormColInput
              label="Payment date"
              name={field("payment_date")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.payment_date, <LpDateTimePicker />)}
            </FormColInput>
            <FormColInput
              label="Payment type"
              name={field("payment_type")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.payment_type, <Input />)}
            </FormColInput>

            <FormColInput
              label="Payment method"
              name={field("payment_method")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.payment_method, <Input />)}
            </FormColInput>

            <FormColInput
              label="Payment description"
              name={field("payment_description")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.payment_description, <Input />)}
            </FormColInput>

            <FormColInput
              label="Payment details"
              name={field("beneficiary_details")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.beneficiary_details, <Input.TextArea />)}
            </FormColInput>
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
