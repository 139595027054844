import { App, Select } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { CrudOperations, ResponseBase } from "api";
import { StatusOption } from "./stateStepUtils";

interface Option {
  value: string | string[];
  label: string | React.ReactNode;
}

export interface StateSelectorCommonProps {
  id?: string;
  state?: string;
  endpoint: CrudOperations<any>;
  invalidateKeys?: string[];
  options: Option[];
  dataOptions?: StatusOption[];
}

export const StateSelectorCommon: React.FC<StateSelectorCommonProps> = ({
  id,
  endpoint,
  invalidateKeys = [],
  state,
  options: optionsProp,
  dataOptions,
}) => {
  const options = optionsProp; // ?? defaultStatusOptions;
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const updateOrderStateMutation = useMutation<
    ResponseBase<any>,
    any,
    FormData
  >((data) => endpoint.update(id!, data), {
    onSuccess: (d, variables) => {
      queryClient.invalidateQueries(endpoint.key[0]);
      queryClient.invalidateQueries(endpoint.key);
      for (const key of invalidateKeys) {
        queryClient.invalidateQueries([key]);
      }
      const newStateLabel = dataOptions?.find((x: any) =>
        Array.isArray(x?.value)
          ? x?.value.includes(variables.get("state"))
          : x?.value === variables.get("state")
      )?.label;
      if (newStateLabel) {
        message.success(`State changed to "${newStateLabel}"`);
      } else {
        message.success(`State changed`);
      }
    },
    onError: (e) => {
      message.error(e.response.data.message);
    },
  });

  if (!id) return null;
  if (!state) return null;

  const handleOrderStateChange = (newOrderState: string) => {
    const fd = new FormData();
    fd.append("state", newOrderState);
    updateOrderStateMutation.mutate(fd);
  };

  const selectedOption = getSelectedOption(state, dataOptions);
  const availableActions = selectedOption?.availableActions;
  const selectValue = getOptionValue(state, options);

  const getIsDisabled = (option: Option) => {
    const optionValue = Array.isArray(option.value)
      ? option.value[0]
      : option.value;
    if (option.value === selectValue) {
      return false;
    }
    if (!availableActions) {
      return false;
    }
    if (!availableActions.includes(optionValue)) {
      return true;
    }
    return false;
  };

  const selectOptions = options.map((option) => ({
    ...option,
    value: Array.isArray(option.value) ? option.value[0] : option.value,
    disabled: getIsDisabled(option),
  }));

  return (
    <Select
      options={selectOptions}
      value={selectValue}
      loading={updateOrderStateMutation.isLoading}
      onChange={handleOrderStateChange}
      popupMatchSelectWidth={false}
      placement="bottomRight"
    />
  );
};

const getSelectedOption = (
  state: string,
  options?: StatusOption[]
): StatusOption | undefined => {
  const selectedOption = options?.find(
    (option) =>
      option.value === state ||
      (Array.isArray(option.value) && option.value?.includes(state))
  );
  return selectedOption;
};

const getOptionValue = (state: string, options: Option[]) => {
  const selectedOption = options?.find(
    (option) =>
      option.value === state ||
      (Array.isArray(option.value) && option.value?.includes(state))
  );
  if (selectedOption) {
    return Array.isArray(selectedOption)
      ? selectedOption.value[0]
      : (selectedOption.value as string);
  }
  return state;
};
