import { App, Button, Divider, Form } from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import { useState } from "react";
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HttpErrorType, BaseModel, ResponseBase, CrudOperations } from "api";
import React from "react";
import { useDeleteConfirm } from "./useDeleteConfirm";
import { useResponseErrorHandler } from "./useResponseErrorHandler";
import { Callbacks, useFormData } from "./useFormData";
import { useNavigate } from "react-router-dom";

export interface Exec<T> {
  updateData: (record: T) => void;
  preview: (record?: T) => void;
}

export interface BaseField {
  onEdit?: (text?: any, record?: any, index?: number) => React.ReactNode;
  onView?: (text?: any, record?: any, index?: number) => React.ReactNode;
  onChange?: (value: any, record: any, exec: Exec<any>) => void;
  name: string;
  dataIndex?: string | string[];
  title: string;
  required?: boolean;
  readonly?: boolean;
}

export interface TextField extends BaseField {
  type: "text";
}

export interface PlainField extends BaseField {
  type: "plain";
}

export interface NumberField extends BaseField {
  type: "number";
  min?: number;
  max?: number;
}

export interface CurrencyField extends BaseField {
  type: "currency";
  min?: number;
}

export interface QuantityField extends BaseField {
  type: "quantity";
  min?: number;
}

export interface DateField extends BaseField {
  type: "date";
  disablePastDates?: boolean;
}

export interface SelectField extends BaseField {
  type: "select";
  options: { value: string | number; label: string }[];
}

export interface XhrSelectField extends Omit<BaseField, "onChange"> {
  onLink?: (text?: any, record?: any, index?: number) => string;
  type: "xhr-select";
  endpoint: CrudOperations<any>;
  options: (item: any) => { value: string; label: string };
  onChange?: (id: any, value: any, record: any, exec: Exec<any>) => void;
}

export type Field =
  | TextField
  | NumberField
  | CurrencyField
  | QuantityField
  | DateField
  | SelectField
  | XhrSelectField
  | PlainField;

export const ComputedBaseField = ({ record, fieldName, renderField }: any) => {
  const [value, setValue] = useState(record[fieldName]);
  const updateHandler = (record: any) => {
    setValue(record[fieldName]);
  };
  stateHandlers.fieldSetters[fieldName] = updateHandler;
  return <span>{renderField(value)}</span>;
};

const stateHandlers: any = {
  fieldSetters: {},
};

interface AntDFieldError {
  errors: string[];
  name: string;
}

export interface LPForm<T> {
  form: FormInstance<T>;
  data: T;
  field: (name: string) => string | undefined;
  showForm: boolean;
  isLoading: boolean;
  isEditMode: boolean;
  viewOrEdit: (
    view: React.ReactNode | string,
    edit: React.ReactNode,
    secondaryCondition?: boolean
  ) => React.ReactNode;
  setViewMode: () => void;
  setEditMode: () => void;
  isSubmitting?: boolean;
  save: (data: FormData) => void;
  confirmDelete: () => void;
  actions?: React.ReactNode[];
  standardSaveButton: React.ReactNode;
  exec: Exec<T>;
  toFormData: (values: any, callbacks?: Callbacks<any> | undefined) => FormData;
}

export interface Props<T> {
  id?: string;
  initialData?: any;
  onAfterSave?: (data: T) => void;
  onAfterDelete?: (data: any) => void;
  onAfterCreateNavigateTo?: (data: any) => string;
  onAfterDeleteNavigateTo?: (data: any) => string;
  onSetViewMode?: () => void;
  endpoint: CrudOperations<T>;
  isEdit?: boolean;
  allowedActions?: (data: T) => ("edit" | "delete" | "create")[];
  extraActions?: React.ReactNode[];
  showActionTitles?: boolean;
  customSubmitAction?: () => void;
  mustHandleErrors?: boolean;
}

export const useLPForm = <T extends BaseModel>({
  id,
  initialData = {},
  endpoint,
  isEdit: initialIsEdit,
  onAfterSave,
  onAfterDelete,
  onAfterCreateNavigateTo,
  onAfterDeleteNavigateTo,
  onSetViewMode,
  allowedActions = () => ["edit", "delete"],
  extraActions = [],
  showActionTitles = false,
  customSubmitAction,
  mustHandleErrors = true,
}: Props<T>): LPForm<T> => {
  const { collect: toFormData } = useFormData<T>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const [form] = useForm();
  const navigate = useNavigate();

  const isExistingEntity = !!id && Number(id) >= 0;

  const { handleError } = useResponseErrorHandler();
  const {
    data: response,
    isLoading,
    error,
  } = useQuery(
    [endpoint.key, id],
    async () => {
      const res = await endpoint.get?.(id!);
      return res;
    },
    {
      enabled: isExistingEntity,
      keepPreviousData: true,
    }
  );
  handleError(mustHandleErrors && error);

  const data = isExistingEntity ? response?.data : initialData;

  const updateMutation = useMutation<ResponseBase<T>, any, FormData>(
    (data) => {
      if (!!id && id !== "-1" && id !== "create") {
        return endpoint.update?.(id, data);
      } else {
        return endpoint.create?.(data);
      }
    },
    {
      onMutate: () => {
        setIsSubmitting(true);
      },
      onError: (e) => {
        resolveValidationServerErrors(form, e);
        setIsSubmitting(false);
        const errorMsg = [e.response.data.message];
        if (e?.response?.data?.data) {
          for (const [key, value] of Object.entries(e?.response?.data?.data)) {
            errorMsg.push(...(value as []));
          }
        }

        message.error(
          errorMsg.map((msg) => (
            <>
              {msg}
              <br />
            </>
          ))
        );
      },
      onSuccess: (response) => {
        queryClient.invalidateQueries(endpoint.key);
        message.success(response.message);
        setIsSubmitting(false);
        setViewMode();
        onAfterSave?.(response.data);
        if (response.data.id && !id) {
          const navTo = onAfterCreateNavigateTo?.(response.data);
          if (navTo) {
            navigate(navTo, { replace: true });
          }
        }
      },
    }
  );

  const { askToDelete } = useDeleteConfirm({
    crud: endpoint,
    onAfterDeleteNavigateTo,
    onSuccess: onAfterDelete,
  });

  const [isEditMode, setIsEditMode] = useState<boolean>(initialIsEdit ?? false);

  const setEditMode = () => {
    setIsEditMode(true);
    form.resetFields();
  };

  const setViewMode = () => {
    onSetViewMode?.();
    setIsEditMode(false);
  };

  const viewOrEdit = (
    view: React.ReactNode,
    edit: React.ReactNode,
    showEdit?: boolean
  ) => {
    if (showEdit !== undefined && !showEdit) return view;
    return isEditMode ? edit : view;
  };

  const isAllowedAction = (action: "edit" | "delete" | "create") =>
    allowedActions?.(data).includes(action);

  const [previewRecord, setPreviewRecord] = useState<T | null>(null);

  useQuery({
    queryKey: [endpoint.key, "previewRecord"],
    queryFn: () => endpoint.preview?.(previewRecord),
    onSuccess(data) {
      if (!data) return;
      if (data.data) {
        setPreviewRecord(null);
        exec.updateData({
          ...data.data,
          ...form.getFieldsValue(),
        });
      }
    },
    enabled: !!previewRecord,
  });

  const exec: Exec<T> = {} as Exec<T>;

  exec.updateData = (record: T) => {
    form.setFieldsValue(record);
    Object.keys(stateHandlers.fieldSetters).forEach((key) => {
      stateHandlers.fieldSetters[key](record);
    });
  };

  exec.preview = (record?: T) =>
    setPreviewRecord(record ? record : { ...form.getFieldsValue() });

  const actions = [];

  if (isAllowedAction("edit")) {
    if (isEditMode) {
      actions.push(
        <Button
          key={"saveBtnKey"}
          loading={isSubmitting}
          type="primary"
          onClick={() => {
            if (customSubmitAction) {
              customSubmitAction();
              return;
            }
            form.submit();
          }}
        >
          <SaveOutlined />
          {showActionTitles && "Save"}
        </Button>
      );
      (id || isAllowedAction("create")) &&
        actions.push(
          <Button
            key={"cancelBtnKey"}
            loading={isSubmitting}
            onClick={setViewMode}
          >
            <CloseOutlined />
            {showActionTitles && "Cancel"}
          </Button>
        );
    } else if (!!id) {
      actions.push(
        <Button key={"editBtnKey"} onClick={setEditMode}>
          <EditOutlined />
          {showActionTitles && "Edit"}
        </Button>
      );
    }
  }

  actions.push(...extraActions);

  if (isAllowedAction("delete") && !!id)
    actions.push(
      <Button
        key={"deleteBtnKey"}
        onClick={() => askToDelete(id)}
        icon={<DeleteOutlined />}
      ></Button>
    );

  if (isAllowedAction("create") && !id && !isEditMode) {
    actions.push(
      <Button
        key={"createBtnKey"}
        onClick={setEditMode}
        icon={<PlusOutlined />}
      ></Button>
    );
  }

  const standardSaveButton = isEditMode ? (
    <>
      <Divider />
      <Form.Item wrapperCol={{ offset: 0, span: 16, sm: { offset: 8 } }}>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
          Зберегти
        </Button>
      </Form.Item>
    </>
  ) : null;

  const resolveValidationServerErrors = (
    form: any,
    error: HttpErrorType
  ): void => {
    const data = error?.response?.data as any;
    if (!data || data.message !== "Validation error") return;
    const errorData = data.data as { [key: string]: string[] };
    const antDFormErrors: AntDFieldError[] = [];
    for (const key in errorData) {
      if (typeof errorData[key] === "object" && errorData[key].length) {
        antDFormErrors.push({
          name: key,
          errors: [errorData[key][0] as any as string],
        });
        continue;
      }
    }
    const allAntDFormFields: AntDFieldError[] = Object.keys(
      form.getFieldsValue()
    ).map((field) => ({
      name: field,
      errors: [],
    }));
    /*
     * Merging all fields with the recent errors. This is needed to clear previous errors that are not present in the recent response
     */
    const fieldErrors = [...allAntDFormFields, ...antDFormErrors];
    // console.log("!!!!!!!! fieldErrors", fieldErrors);
    form.setFields(fieldErrors);
  };

  const showForm =
    (isEditMode && !isLoading && !error) ||
    (!isEditMode && !!id && !isLoading && !error);
  const field = (name: string) => {
    return isEditMode ? name : undefined;
  };

  return {
    data,
    isLoading,
    showForm,
    field,
    isEditMode,
    toFormData,
    setEditMode,
    setViewMode,
    viewOrEdit,
    form,
    actions,
    save: updateMutation.mutate,
    confirmDelete: () => id && askToDelete(id),
    isSubmitting,
    standardSaveButton,
    exec,
  };
};
