import { App, Button, Card, Form, Input, Modal, Row } from "antd";
import { User, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useState } from "react";

export interface UpdatePasswordModalProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export const UpdatePasswordModal = ({
  id,
  open,
  onClose,
}: UpdatePasswordModalProps) => {
  const { message } = App.useApp();
  const {
    data: respData,
    showForm,
    isSubmitting,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<User>({
    id,
    endpoint: api.me,
    allowedActions: () => ["edit"],
    onAfterSave(data) {
      message.success("Password updated");
      onClose();
    },
  });

  const onFinish = (data: any) => {
    console.log("!!! data", data);
  };

  return (
    <Modal
      open={open}
      title="Update password"
      onCancel={() => onClose()}
      okText="Update"
      okButtonProps={{ loading: isSubmitting }}
      onOk={() => form.submit()}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Current password"
          name="current_password"
          rules={[{ required: true, message: "Current password required" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New password"
          name="new_password"
          rules={[{ required: true, message: "New password required" }]}
        >
          <Input.Password autoComplete={"off"} />
        </Form.Item>
        <Form.Item
          label="Repeat password"
          name="new_password_copy"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Password required" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
