import { Card } from "antd";
import { api } from "api";
import { DataTable } from "components/DataTable/DataTable";

export const Orders = () => {
  return (
    <Card>
      <DataTable
        columns={columns}
        endpoint={api.order}
        tableId="orders-list-page"
      />
    </Card>
  );
};

const columns = [
  {
    key: "id",
    title: "ID",
    dataIndex: "id",
    sorter: true,
  },
  {
    key: "venue",
    title: "Venue",
    dataIndex: ["venue", "name"],
    sorter: true,
  },
  {
    key: "status",
    title: "status",
    dataIndex: "status",
    sorter: true,
  },
];
