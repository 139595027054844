import { Button, Card } from "antd";
import { api } from "api";
import { DataTable } from "components/DataTable/DataTable";
import { useAuthContext } from "AuthProvider";
import { useState } from "react";
import { UploadTaskFilesModal } from "./UploadTaskFilesModal";
import { Crud } from "hooks/useCrud";

export interface Props {
  taskId: string;
  state: string;
}

export const TaskTabTaskFiles = ({ taskId, state }: Props) => {
  const { hasAdminRole, hasUserRole } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const { del } = Crud.taskFile.useDelete();

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const columns = [
    // ...(hasAdminRole
    //   ? [
    //       {
    //         key: "id",
    //         title: "ID",
    //         dataIndex: "id",
    //       },
    //     ]
    //   : []),
    {
      key: "file_name",
      title: "Filename",
      dataIndex: "file_name",
    },
    ...(hasAdminRole
      ? [
          {
            key: "file_path",
            title: "File path",
            dataIndex: "file_path",
          },
        ]
      : []),
    {
      key: "url",
      title: "Url",
      dataIndex: "url",
      render: (url: string) => <a href={url}>download</a>,
    },
  ];

  const extraActions: any = [];
  if (hasUserRole && state === "active") {
    extraActions.push(
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add files
      </Button>
    );
  }

  return (
    <>
      <DataTable
        showIndex
        endpoint={api.taskFile}
        extraFilters={{ task_id: taskId }}
        columns={columns}
        tableId={"TaskTabTaskFiles"}
        extraActions={extraActions}
        onDelete={
          hasUserRole
            ? (item: any) => {
                del(item.id);
              }
            : undefined
        }
      />
      <UploadTaskFilesModal
        open={isOpen}
        onClose={handleOnClose}
        taskId={taskId}
      />
    </>
  );
};
