import { Button, Form, Input, InputNumber, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Bid, api } from "api";
import { Crud } from "hooks/useCrud";
import { useLPForm } from "hooks/useLPForm";

export interface Props {
  auctionId: string;
  open: boolean;
  onClose: () => void;
}

export const AuctionBidModal: React.FC<Props> = ({
  auctionId,
  open,
  onClose,
}) => {
  const auction = Crud.auction.useGet(auctionId);

  const {
    data: respData,
    form,
    save,
    field,
    setEditMode,
    toFormData,
  } = useLPForm<Bid>({
    id: undefined,
    initialData: {},
    isEdit: true,
    endpoint: api.bid,
    onAfterSave(data) {
      setEditMode();
      handleClose();
    },
    // onAfterCreateNavigateTo: (data) => `/platform/auctions/${auctionId}`,
    // onAfterDeleteNavigateTo: (data) => "/platform/auction",
  });
  const data = respData || {};

  const handleFinish = (fd: any) => {
    const data = {
      ...fd,
      auction_id: auctionId,
    };
    save(toFormData(data));
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Bid"
      open={open}
      destroyOnClose={true}
      onCancel={handleClose}
      footer={
        <>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Place bid
          </Button>
          <Button
            type="default"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </>
      }
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 852 }}
        initialValues={data}
        form={form}
        onFinish={handleFinish}
        autoComplete="off"
        labelWrap
      >
        <Form.Item
          label="Price"
          name={field("price")}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} addonAfter={auction?.data?.data?.currency} />
        </Form.Item>
        <Form.Item
          label="Bid justification and execution details"
          name={field("comment")}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
