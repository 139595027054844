import { Crud } from "hooks/useCrud";
import { HtmlContent } from "./GenericPage";

export const LandingPage = () => {
  const { data: resp, isLoading } = Crud.page.useList({
    extraParams: { code: "landing-page" },
  });
  const data = resp?.items?.[0];
  const htmlString = data?.content || "";
  if (isLoading) return null;

  return (
    <div>
      <BackgroundBlock imageUrl="hero-bg.jpeg" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          fontSize: "1.2em",
        }}
      >
        <div style={{ maxWidth: "908px", width: "908px", padding: "24px" }}>
          <h1>{data?.title}</h1>
          <HtmlContent htmlString={htmlString} />
        </div>
      </div>
    </div>
  );
};

const BackgroundBlock = ({ imageUrl }: { imageUrl: string }) => {
  const style = {
    width: "100%", // Ensures the div takes the full width of its container
    height: "300px", // Initial height, can be adjusted based on your needs
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  };

  return <div style={style} />;
};
