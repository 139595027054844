import { RefSelectProps } from "antd";
import { Currency, api } from "api";
import { AjaxSelect, AjaxSelectProps } from "components/AjaxSelect";
import { Ref } from "react";

export type Props = Omit<AjaxSelectProps<Currency>, "endpoint" | "fields">;

const AjaxSelectCurrencyComponent = (props: Props) => {
  return (
    <AjaxSelect
      {...props}
      endpoint={api.currency}
      doGet={false}
      fields={(currency) => ({
        value: currency.code,
        label: currency.code,
      })}
    />
  );
};

export const AjaxSelectCurrency = AjaxSelectCurrencyComponent as <Props>(
  props: Props & { ref?: Ref<RefSelectProps> }
) => JSX.Element;
