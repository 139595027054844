import { Card, Input } from "antd";
import { Contract, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { LinkToAuction } from "components/Links/LinkToAuction";
import { AjaxSelect } from "components/AjaxSelect";
import { LpDateTimePicker } from "components/LpDateTimePicker";
import { ContractState } from "components/State/ContractState";
import { AjaxSelectCurrency } from "components/Select/AjaxSelectCurrency";

export const ContractEditPage = () => {
  const { contractId: id } = useParams<{ contractId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Contract>({
    id,
    isEdit: !id,
    endpoint: api.contract,
    onAfterCreateNavigateTo: (data) => `/platform/contracts/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/contracts",
  });

  const data = respData || {};

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? "Contract" : "Create Contract"}
          actions={
            <>
              {data?.state === "draft" && actions}
              {id && <ContractState.Selector id={id} state={data?.state} />}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            labelWrap={true}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 1200 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Auction"
              name={field("auction_id")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                <LinkToAuction {...data.auction} />,
                <AjaxSelect
                  endpoint={api.auction}
                  filter={{ state: "closed", "contract:doesnthave": 1 }}
                />
              )}
            </FormColInput>
            {data?.id && (
              <>
                <FormColInput label="Author">{data.author?.name}</FormColInput>
                <FormColInput label="User">{data.user?.name}</FormColInput>
              </>
            )}
            <FormColInput
              label="Price"
              name={field("price")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.price, <Input />)}
            </FormColInput>
            <FormColInput
              label="Currency"
              name={field("currency")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.currency, <AjaxSelectCurrency />)}
            </FormColInput>
            <FormColInput
              label="Link to document"
              name={field("link_to_document")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.link_to_document, <Input />)}
            </FormColInput>
            <FormColInput
              label="Start date"
              name={field("start_date")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.start_date, <LpDateTimePicker />)}
            </FormColInput>
            <FormColInput
              label="End date"
              name={field("end_date")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.end_date, <LpDateTimePicker />)}
            </FormColInput>
            <FormColInput
              label="Beneficiary details"
              name={field("beneficiary_details")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.beneficiary_details, <Input.TextArea />)}
            </FormColInput>
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
