import { SorterResult } from "antd/es/table/interface";
import { useLocalStorage } from "./useLocalStorage";

export interface ListColumnSetup {
  [key: string]: string[];
}

export interface KeyStringValues {
  [key: string]: string;
}

export interface AccountSettings {
  listVisibleCols: ListColumnSetup;
  sortOrders: KeyStringValues;
}

const DEFAULT_ACCOUNT_SETTINGS: AccountSettings = {
  listVisibleCols: {},
  sortOrders: {},
};

export const useAccountSettings = () => {
  const [accountSettings, saveAccountSettings] =
    useLocalStorage<AccountSettings>(
      "accountSettings",
      DEFAULT_ACCOUNT_SETTINGS
    );

  const visibleCols = (queryId: string): string[] => {
    return accountSettings?.listVisibleCols[queryId]
      ? accountSettings.listVisibleCols[queryId]
      : [];
  };

  const saveVisibleCols = (queryId: string, columns: string[]): void => {
    const nextAccountSettings = { ...accountSettings };
    nextAccountSettings.listVisibleCols = {
      ...nextAccountSettings.listVisibleCols,
      [queryId]: columns,
    };
    saveAccountSettings(nextAccountSettings);
  };

  const sortOrder = (queryId: string): string | undefined => {
    return accountSettings?.sortOrders?.[queryId]
      ? accountSettings.sortOrders[queryId]
      : undefined;
  };

  const getSortOrder = (tableKey: string): SorterResult<any> | SorterResult<any>[] | undefined => {
    const sortOder = accountSettings?.sortOrders?.[tableKey]
    if (!sortOder) return undefined;
    try {
      const sortOrder = JSON.parse(sortOder);
      return sortOrder;
    } catch (error) {
      return undefined;
    }
  };

  const saveSortOrder = (queryId: string, sortOrder: string | null): void => {
    if (sortOrder === null) return;
    const nextAccountSettings = { ...accountSettings };
    nextAccountSettings.sortOrders = {
      ...nextAccountSettings.sortOrders,
      [queryId]: sortOrder,
    };
    saveAccountSettings(nextAccountSettings);
  };

  const forgetSortOrder = (queryId: string): void => {
    const nextAccountSettings = { ...accountSettings };
    delete nextAccountSettings.sortOrders[queryId];
    saveAccountSettings(nextAccountSettings);
  };

  return {
    visibleCols,
    saveVisibleCols,
    sortOrder,
    getSortOrder,
    saveSortOrder,
    forgetSortOrder,
  };
};
