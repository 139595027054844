import { Card } from "antd";
import { User, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";
import { renderDateTime } from "utils/renderer";

export const UserListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/users/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Users"
        // actions={
        // <ButtonCreateNavigate link="/platform/users/create">
        //   Users
        // </ButtonCreateNavigate>
        // }
      />
      <DataTable<User>
        endpoint={api.user}
        columns={columns}
        extraFilters={{ "roles>code": "user" }}
        tableId={"UserListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Email verified at",
    dataIndex: "email_verified_at",
    key: "email_verified_at",
    sorter: true,
    render: renderDateTime,
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    sorter: true,
  },
];
