import { App, Form, Input, Modal } from "antd";
import { ResponseBase, api, kmAxios } from "api";
import { AxiosResponse } from "axios";
import { REACT_APP_API } from "env";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

export interface InviteAdminModalProps {
  open: boolean;
  onClose: () => void;
}

interface MutationProps {
  email: string;
}

export const InviteAdminModal: React.FC<InviteAdminModalProps> = ({
  open,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const mutation = useMutation<
    AxiosResponse<ResponseBase<any>>,
    any,
    MutationProps
  >(
    (formData) =>
      kmAxios.post(`${REACT_APP_API}/invite-admin`, null, { params: formData }),
    {
      onError: (e) => {
        message.error(e.response.data.message);
      },
      onSuccess: (response) => {
        message.success(response.data.message);
        queryClient.invalidateQueries(api.user.key);
        onClose();
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    }
  );

  const onFinish = (data: any) => {
    setIsSubmitting(true);
    mutation.mutate(data);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ loading: isSubmitting }}
      onCancel={handleCancel}
      okText="Send invitation"
    >
      <h2>Invite Admin</h2>
      <Form initialValues={{}} form={form} onFinish={onFinish}>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            { required: true },
            {
              validator: validateEmail,
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const validateEmail = (rule: any, value: any) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value || pattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter a valid email address"));
};
