import React from "react";
import { ConfigProvider, App as AntApp, ThemeConfig } from "antd";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const customTheme: ThemeConfig = {
  token: {
    fontFamily: "Raleway, helvetica neue, helvetica, arial, sans-serif",
    fontSize: 14,
    colorPrimary: "#f62765",
  },
};

root.render(
  <React.StrictMode>
    <AntApp style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <ConfigProvider theme={customTheme}>
        <App />
      </ConfigProvider>
    </AntApp>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
