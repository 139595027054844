import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export interface BreadcrumbsProps {
  items: { title: string; to?: string }[];
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <Breadcrumb
      style={{ margin: "0 0 20px 0" }}
      items={items.map((item) => {
        if (!item.to)
          return {
            title: item.title,
          };
        return {
          title: <Link to={item.to}>{item.title}</Link>,
        };
      })}
    />
  );
};
