import { api } from "api";
import {
  CheckOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
  LikeOutlined,
  StopOutlined,
  LaptopOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { GenerateStatuses } from "./stateStepUtils";

const iconStyle = { paddingRight: "6px" };

export const ProposalState = GenerateStatuses({
  endpoint: api.proposal,
  options: [
    {
      value: "draft",
      label: "Draft",
      icon: <FileTextOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "changes_requested",
      label: "Changes Requested",
      icon: (
        <ExclamationCircleOutlined style={{ ...iconStyle, color: "orange" }} />
      ),
    },
    {
      value: "submitted",
      label: "Submitted",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
    },
    {
      value: "approved",
      label: "Approved",
      icon: <LikeOutlined style={{ ...iconStyle, color: "blue" }} />,
    },
    {
      value: "rejected",
      label: "Rejected",
      icon: <StopOutlined style={{ ...iconStyle, color: "red" }} />,
    },
    {
      value: "in_progress",
      label: "In Progress",
      icon: <LaptopOutlined style={{ ...iconStyle, color: "yellow" }} />,
    },
    {
      value: "completed",
      label: "Completed",
      icon: <CheckOutlined style={{ ...iconStyle, color: "green" }} />,
    },
  ],
});
