import { Button, Flex, Input, Space } from "antd";
import { useAvailableFilterOptions } from "../SmartFilterDialog";
import { useState } from "react";
import { FilterOption } from "api";
import { ConfiguredSmartFilter } from "../useSmartFilters";

export interface FilterProps {
  modelName: string;
  smartFilter: ConfiguredSmartFilter;
  handleChangeOperand: (operand: FilterOption) => void;
}

export const FilterText = ({
  modelName,
  smartFilter,
  handleChangeOperand,
}: FilterProps) => {
  const [search, setSearch] = useState<string>();
  console.log("!!! text not strict");
  const { filterOptions } = useAvailableFilterOptions(
    modelName,
    smartFilter?.filter?.field,
    search
  );

  return (
    <Flex vertical gap="small">
      <Space.Compact>
        <Input.Search
          placeholder={smartFilter?.filter!.label}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onPressEnter={() => {
            if (!search) return;
            handleChangeOperand({
              id: search,
              name: search,
            });
          }}
          onSearch={() => {
            if (!search) return;
            handleChangeOperand({
              id: search,
              name: search,
            });
          }}
        />
      </Space.Compact>
      <Flex vertical>
        {filterOptions.map((fo) => (
          <Button
            key={fo.id}
            type="text"
            onClick={() => {
              handleChangeOperand(fo);
            }}
            style={{ textAlign: "left" }}
          >
            {fo.name}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
