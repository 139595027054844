import { api } from "api";
import {
  CheckOutlined,
  CloudUploadOutlined,
  ClockCircleOutlined,
  LikeOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { GenerateStatuses } from "./stateStepUtils";

const iconStyle = { paddingRight: "6px" };

export const TaskStateUser = GenerateStatuses({
  endpoint: api.task,
  options: [
    {
      value: ["active"],
      label: "Active",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
      availableActions: ["planned", "submitted", "cancelled"],
      description:
        "Attach files required in the task description, and submit the task for approval.",
    },
    {
      value: "submitted",
      label: "Submitted",
      icon: <CloudUploadOutlined style={{ ...iconStyle, color: "green" }} />,
      availableActions: ["active", "approved", "cancelled"],
      description: "Wait for approval.",
    },
    {
      value: "approved",
      label: "Approved",
      icon: <LikeOutlined style={{ ...iconStyle, color: "blue" }} />,
      availableActions: ["submitted", "completed", "cancelled"],
      description: "Wait for payment.",
    },
    {
      value: "completed",
      label: "Completed",
      icon: <CheckOutlined style={{ ...iconStyle, color: "#f62765" }} />,
      availableActions: [],
      description: "Task is completed. No further action is required.",
    },
    {
      value: "cancelled",
      label: "Cancelled",
      icon: <StopOutlined style={{ ...iconStyle, color: "grey" }} />,
      availableActions: [],
      description: "Task is cancelled. No further action is required.",
    },
  ],
});
