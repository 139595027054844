import { Button, Form, Modal } from "antd";
import { UploadCustom } from "components/UploadCustom";
import { UploadOutlined } from "@ant-design/icons";
import { Crud } from "hooks/useCrud";
import { useForm } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";

export interface UploadTaskFilesModalProps {
  taskId: string;
  open: boolean;
  onClose: () => void;
}

export const UploadTaskFilesModal = ({
  taskId,
  open,
  onClose,
}: UploadTaskFilesModalProps) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadStarted, setUploadStarted] = useState(false);

  const handleChange = ({ fileList: newFileList }: any) =>
    setFileList(newFileList);

  const handleOnFinish = () => {
    setUploadStarted(true);
  };

  useEffect(() => {
    if (!uploadStarted) return;
    const inProgress = fileList.find(
      (file) => file.status === "uploading" && file.percent === 0
    );
    if (!inProgress) {
      const toUpload = fileList.find((file) => file.status === undefined);
      setFileList((prev) =>
        prev.map((file) =>
          file.uid === toUpload?.uid
            ? { ...file, status: "uploading", percent: 0 }
            : file
        )
      );
      if (!toUpload) {
        setUploadStarted(false);
        setFileList([]);
        onClose();
      }
    }
  }, [uploadStarted, fileList]);

  useEffect(() => {
    const uploading = fileList.find(
      (file) => file.status === "uploading" && file.percent === 0
    );
    if (uploading) {
      uploadFile(uploading);
    }
  }, [fileList]);

  const uploadFile = (toUpload: any) => {
    const fd = new FormData();
    fd.append("task_id", taskId);
    fd.append("file", toUpload?.originFileObj);
    save(fd);
  };

  const [form] = useForm();
  const { save } = Crud.taskFile.useSave({
    onAfterSuccess: () => {
      setFileList((prev) =>
        prev.map((file) =>
          file.status === "uploading" && file.percent === 0
            ? { ...file, status: "uploading", percent: 100 }
            : file
        )
      );
    },
  });

  return (
    <Modal
      title="Files Upload"
      open={open}
      onCancel={onClose}
      okButtonProps={{ loading: uploadStarted }}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 852 }}
        // initialValues={initialValues}
        form={form}
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Form.Item
          name="file"
          label="File"
          rules={[{ required: true }]}
          // valuePropName={"fileList"}
          // getValueFromEvent={(e) => {
          //   return Array.isArray(e) ? e : e && e.fileList;
          // }}
        >
          <UploadCustom
            isEditMode={true}
            fileList={fileList}
            onChange={(e) => {
              const data = Array.isArray(e) ? e : e && e.fileList;
              handleChange({ fileList: data });
            }}
            maxCount={10}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </UploadCustom>
        </Form.Item>
      </Form>
    </Modal>
  );
};
