import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  Row,
  Col,
  Card,
  Alert,
  App,
  Typography,
  Checkbox,
} from "antd";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation, Link } from "react-router-dom";

import type { AuthResponse, ResponseBase } from "../api";
import { REACT_APP_API } from "../env";
import { useAuthContext } from "../AuthProvider";
import type { AxiosResponse } from "axios";

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

export interface RegisterProps {
  email: string;
  password: string;
  c_password: string;
}

export const RegisterPage: React.FC = () => {
  const { message } = App.useApp();
  // todo: mistake, must use authProvider
  const [token, setToken] = useState<string | null>(null);
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const [authFailed, setAuthFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAgreedTerms, setIsAgreedTerms] = useState<boolean>(false);
  const [isAcceptPrivacyPolicy, setIsAcceptPrivacyPolicy] =
    useState<boolean>(false);

  const mutation = useMutation<
    AxiosResponse<ResponseBase<AuthResponse>>,
    any,
    RegisterProps
  >(
    (formData) =>
      axios.post(`${REACT_APP_API}/register`, null, { params: formData }),
    {
      onError: (e) => {
        const keys = Object.keys(e.response.data.data);
        let em = "";
        for (const key of keys) {
          const errors = e.response.data.data[key];
          em += key + ": " + errors.join(", ");
        }
        setErrorMessage(em);
        setAuthFailed(true);
      },
      onSuccess: (response) => {
        setAuthFailed(false);
        message.success(response.data.message);
        navigate("/verify");
        // Keep invalidation as it is
        // queryClient.invalidateQueries("getCurrentUser");
        // setToken(response.data.data.token);
      },
    }
  );

  const getCurrentUser = async (): Promise<any> => {
    const { data } = await axios.get(`${REACT_APP_API}/user`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  // deprecated
  useQuery(["getCurrentUser"], getCurrentUser, {
    enabled: !!token,
    onSuccess: (data) => {
      console.log("!!! getCurrentUser", data);
      login(token!, data);
      navigate("/platform");
    },
  });

  const onFinish = ({ email, password, c_password }: RegisterProps) => {
    mutation.mutate({ email, password, c_password });
  };

  return (
    <Row
      justify="center"
      style={{
        minHeight: "100vh",
        alignItems: "start",
        backgroundColor: "var(--tme-grey-6)",
      }}
    >
      <Col style={{ display: "flex", padding: "22px 12px" }}>
        <Card
          style={{ width: "400px", marginTop: "calc((100vh - 620px) / 2)" }}
        >
          <Form
            name="basic"
            layout="vertical"
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            autoComplete="off"
          >
            <Row justify="center">
              <Col>
                <img
                  src="/fab-logo.png"
                  style={{ height: 80, margin: 16 }}
                  alt="logo"
                />
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  color: "#f62665",
                  fontWeight: 700,
                  fontSize: "20px",
                  fontFamily: "cursive",
                }}
              >
                Creators Platform
              </Col>
            </Row>
            <Divider plain={true}></Divider>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Registration
            </Typography.Title>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email required" }]}
            >
              <Input autoComplete="new-email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Password required" }]}
            >
              <Input.Password autoComplete={"off"} />
            </Form.Item>
            <Form.Item
              label="Repeat password"
              name="c_password"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Password required" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name="terms-and-conditions" valuePropName="checked">
              <Checkbox
                value={isAgreedTerms}
                onChange={(e) => {
                  setIsAgreedTerms(e.target.checked);
                }}
              >
                I have read and accept{" "}
                <Link to="/terms-and-conditions">
                  Terms and Conditions of use
                </Link>
              </Checkbox>
            </Form.Item>
            <Form.Item name="privacy-policy" valuePropName="checked">
              <Checkbox
                value={isAcceptPrivacyPolicy}
                onChange={(e) => {
                  setIsAcceptPrivacyPolicy(e.target.checked);
                }}
              >
                I have read and accept{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                disabled={!isAgreedTerms || !isAcceptPrivacyPolicy}
              >
                Register
              </Button>
              {authFailed && (
                <Alert
                  message={errorMessage}
                  type="error"
                  style={{ marginTop: "22px" }}
                  closable
                  onClose={() => {
                    setErrorMessage(null);
                    setAuthFailed(false);
                  }}
                />
              )}
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
