import { Card } from "antd";
import { Page, User, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";

export const PageListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/pages/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Pages"
        actions={
          <ButtonCreateNavigate link="/platform/pages/create">
            Page
          </ButtonCreateNavigate>
        }
      />
      <DataTable<Page>
        endpoint={api.page}
        columns={columns}
        tableId={"PageListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: true,
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    sorter: true,
  },
  {
    title: "Slug",
    dataIndex: "slug",
    key: "slug",
    sorter: true,
  },
];
