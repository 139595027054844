import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ButtonProps } from "antd/lib/button";

export const EditButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button {...props} type="default">
      <EditOutlined />
      {children}
    </Button>
  );
};
