import {
  App,
  Button,
  DatePicker,
  Flex,
  FormInstance,
  Input,
  Space,
  Table,
} from "antd";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { TablePaginationConfig, ColumnType } from "antd/es/table";
import {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { DEFAULT_TABLE_PARAMS } from "api";
import {
  BaseModel,
  CrudOperations,
  ListFilters,
  ResponseBase,
} from "api/types";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ColumnSelector } from "./ColumnSelector";
import locale from "antd/es/date-picker/locale/uk_UA";

// this styles should be extracted to generic
import { useAccountSettings } from "hooks/useAccountSettings";
import { useSmartFilters } from "../SmartFilters/useSmartFilters";
import { AddSmartFilterButton } from "../SmartFilters/AddSmartFilterButton";
import { ConfigureSmartFilters } from "../SmartFilters/ConfiguredSmartFilters";

function sorterAntToApi<TRecord>(
  sorter: SorterResult<TRecord> | SorterResult<TRecord>[]
) {
  if (!sorter || Array.isArray(sorter)) return null;
  if (!sorter.order || !sorter.column) return null;
  const antSortToApi = {
    ascend: "asc",
    descend: "desc",
  };
  // console.log("!!! sorter", sorter);
  // @ts-ignore
  let field = sorter.column.sortIndex;
  if (!field) {
    field = Array.isArray(sorter.field) ? sorter.field.join(".") : sorter.field;
  }

  return [field, antSortToApi[sorter.order]].join(":");
}

export interface DateFilterConfig {
  show: boolean;
  asFilterKey: string;
}

export interface RangeFilterConfig {
  show: boolean;
  keyFrom: string;
  keyTo: string;
}

export type CustomColumnType<T> = ColumnType<T> & { key: string };
export const EditableRowFormContext = React.createContext<FormInstance<any>>(
  null!
);

export interface DataTableProps<TRecord> {
  tableId: string;
  columns: CustomColumnType<TRecord>[];
  defaultVisibleCols?: string[];
  size?: SizeType;
  showIndex?: boolean;
  onCreate?: {
    text: string;
    action?: () => void;
  };
  onEdit?: (record: TRecord) => void;
  onDelete?: (record: TRecord) => void;
  rowSelection?: TableRowSelection<TRecord>;
  onRowClick?: (record: TRecord, index?: number) => void;
  extraActions?: JSX.Element[];
  extraFilters?: any;
  extraRowActions?: (record: TRecord) => JSX.Element[];
  dateFilterConfig?: DateFilterConfig;
  rangeFilterConfig?: RangeFilterConfig;
  rowClassName?: (record: TRecord, index: number) => string;
  onData?: (data: any) => void;
  components?: any; // todo: import TableComponents<TRecord>;
  FormComponent?: any;
  endpoint: Pick<CrudOperations<TRecord>, "list" | "key">;
  className?: string;
  expandable?: any;
}

export const DataTable = <TRecord extends BaseModel>({
  columns,
  tableId,
  showIndex,
  size = "small",
  onCreate,
  onEdit,
  onDelete,
  onRowClick,
  rowSelection,
  defaultVisibleCols: defaultVisibleColsProp,
  extraActions,
  dateFilterConfig,
  rangeFilterConfig,
  extraFilters = {},
  onData,
  FormComponent,
  components,
  extraRowActions = () => [],
  endpoint,
  className,
  expandable,
}: DataTableProps<TRecord>) => {
  const smartFilters = useSmartFilters(endpoint.key);

  const defaultVisibleCols =
    defaultVisibleColsProp || columns.map((c) => c.key);

  const {
    visibleCols: preservedVisibleCols,
    saveVisibleCols,
    sortOrder: preservedSortOrder,
    saveSortOrder,
    forgetSortOrder,
  } = useAccountSettings();

  const preservedOrder = preservedSortOrder(tableId);
  if (preservedOrder) {
    const [field, direction] = preservedOrder.split(":");
    if (field && direction) {
      const dataCol = columns.find((c) => c.dataIndex === field);
      if (dataCol) {
        if (direction === "desc") dataCol.defaultSortOrder = "descend";
        else if (direction === "asc") dataCol.defaultSortOrder = "ascend";
        else delete dataCol.defaultSortOrder;
      }
    }
  }

  const [listFilters, setListFilters] = useState<ListFilters>(
    preservedSortOrder(tableId)
      ? { ...DEFAULT_TABLE_PARAMS, order: preservedSortOrder(tableId) }
      : DEFAULT_TABLE_PARAMS
  );

  const [visibleCols, setVisibleCols] = useState(
    preservedVisibleCols(tableId).length > 0
      ? preservedVisibleCols(tableId)
      : defaultVisibleCols
  );

  const columnsToDisplay = columns.filter(({ key }) =>
    visibleCols.includes(key)
  );

  const filters = {
    ...listFilters,
    ...extraFilters,
    ...smartFilters.apiFilters,
  };

  const { data, isLoading } = useQuery([endpoint.key, filters], async () => {
    const result = await endpoint.list(filters);
    return onData ? onData(result) : result;
  });

  const items = data?.items ?? [];

  const pagination: TablePaginationConfig = {
    current: data?.page ?? DEFAULT_TABLE_PARAMS.page,
    pageSize: data?.perPage ?? DEFAULT_TABLE_PARAMS.perPage,
    total: data?.totalItems,
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TRecord> | SorterResult<TRecord>[]
  ) => {
    const order = sorterAntToApi(sorter);
    if (order) saveSortOrder(tableId, order);
    else forgetSortOrder(tableId);
    setListFilters((prev) => ({
      ...prev,
      page: pagination.current,
      perPage: pagination.pageSize,
      ...(order && { order }),
    }));
  };

  const handleDateChange = (dateJs: any) => {
    if (dateJs) {
      const date = dateJs.format("YYYY-MM-DD");
      if (dateFilterConfig?.asFilterKey) {
        setListFilters((prev) => {
          const next = { ...prev };
          next[dateFilterConfig.asFilterKey] = date;
          return next;
        });
      }
    } else if (dateFilterConfig?.asFilterKey) {
      setListFilters((prev) => {
        const next = { ...prev };
        delete next[dateFilterConfig.asFilterKey];
        return next;
      });
    }
  };

  const handleColumnSelectorChange = (data: any) => {
    /*
     * Do not allow change visible cols if no cols selected at all.
     */
    if (data.length === 0) return false;
    setVisibleCols(data);
    saveVisibleCols(tableId, data);
  };

  const tableColumns: ColumnType<TRecord>[] = [
    ...(showIndex
      ? [
          {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (_: any, __: any, index: number) => index + 1,
          } as ColumnType<TRecord>,
        ]
      : []),
    ...columnsToDisplay,
    ...(onEdit || onDelete
      ? [
          {
            title: "Actions",
            key: "actions",
            fixed: "right",
            width: 1,
            render: (_: any, record: TRecord) => (
              <Space size="small">
                {onEdit && (
                  <Button size="small" onClick={() => onEdit(record)}>
                    {/* <EditOutlined /> */}
                    <EyeOutlined />
                  </Button>
                )}
                {onDelete && (
                  <Button size="small" onClick={() => onDelete(record)}>
                    <DeleteOutlined />
                  </Button>
                )}
                {extraRowActions(record)}
              </Space>
            ),
          } as ColumnType<TRecord>,
        ]
      : []),
  ];

  const columnSelectorCols = columns.map(({ title, key }) => ({
    title: String(title),
    key,
  }));

  const handleOnRow = (record: TRecord, index?: number) => {
    return {
      onClick: (e: any) => {
        onRowClick?.(record, index);
      },
    };
  };

  return (
    <>
      <div className={className}>
        <Flex
          gap="small"
          style={{
            marginBottom: 16,
            justifyContent: "space-between",
          }}
          // todo: vertical
        >
          <Flex flex="1" gap="small">
            <Space>
              {dateFilterConfig?.show && (
                <DatePicker
                  locale={locale}
                  style={{ minWidth: 120 }}
                  onChange={handleDateChange}
                />
              )}
              {rangeFilterConfig && (
                <DatePicker.RangePicker
                  locale={locale}
                  style={{ minWidth: 120 }}
                  onChange={handleDateChange}
                />
              )}
              {extraActions?.map((action, id) => (
                <React.Fragment key={id}>{action}</React.Fragment>
              ))}
              {onCreate && (
                <Button onClick={onCreate.action} icon={<PlusOutlined />}>
                  <span className={"responsive-button-text"}>
                    {onCreate.text}
                  </span>
                </Button>
              )}
            </Space>
          </Flex>
          <Flex>
            <Space>
              <Input.Search
                style={{ minWidth: 200 }}
                placeholder="text..."
                allowClear
                enterButton="Search"
                onSearch={(search: string) => {
                  setListFilters((prev) => ({
                    ...prev,
                    search,
                  }));
                }}
              />
              <AddSmartFilterButton {...smartFilters} />
              <ColumnSelector
                items={columnSelectorCols}
                selected={visibleCols}
                setItems={handleColumnSelectorChange}
              />
            </Space>
          </Flex>
        </Flex>
        <ConfigureSmartFilters smartFilters={smartFilters} />
        <Table
          scroll={{ x: "max-content" }}
          columns={tableColumns}
          loading={isLoading}
          pagination={pagination}
          rowKey={(record: TRecord) => record.id}
          dataSource={items}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          onRow={handleOnRow}
          size={size}
          showSorterTooltip={true}
          components={components}
          expandable={expandable}
        />
      </div>
    </>
  );
};
