import { Divider as AntDivider } from "antd";

export interface DividerProps {
  type?: "primary" | "secondary";
}

export const Divider: React.FC<DividerProps> = ({ type = "secondary" }) => {
  const colorStyle =
    type === "primary"
      ? { borderBlockStart: "1px solid #f62765" }
      : { borderBlockStart: "1px solid #eff0f0" };
  const paddingStyle = type === "secondary" ? { margin: "0 0 20px 0" } : {};
  return <AntDivider style={{ ...colorStyle, ...paddingStyle }} />;
};
