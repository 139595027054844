import { useAuthContext } from "AuthProvider";
import { useEffect } from "react";

export const SignOutPage = () => {
  const { logout } = useAuthContext();

  useEffect(() => {
    logout();
  }, []);

  return null;
};
