import { RefSelectProps } from "antd";
import { Contract, api } from "api";
import { AjaxSelect, AjaxSelectProps } from "components/AjaxSelect";
import { Ref } from "react";

export type Props = Omit<AjaxSelectProps<Contract>, "endpoint" | "fields">;

const AjaxSelectContractComponent = (props: Props) => {
  return (
    <AjaxSelect
      {...props}
      endpoint={api.contract}
      fields={(contract) => ({
        value: contract.id,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>CONTRACT-{contract.id}</span>
            <span style={{ color: "grey" }}>{contract?.name}</span>
          </div>
        ),
      })}
    />
  );
};

export const AjaxSelectContract = AjaxSelectContractComponent as <Props>(
  props: Props & { ref?: Ref<RefSelectProps> }
) => JSX.Element;
