import { App, Form } from "antd";
import {
  BaseModel,
  CrudOperations,
  PaginatedResponse,
  ResponseBase,
} from "api";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toFormData } from "./useFormData";
import { useDeleteConfirm } from "./useDeleteConfirm";

export interface EditableTableProps<TRecord> {
  crud: CrudOperations<TRecord>;
  extraProps?: Record<string, string | number | undefined>;
  extraFormProps?: any;
  filterProps?: Record<string, string | number | undefined>;
  extraInvalidate?: string | string[];
  onData?: (data: PaginatedResponse<TRecord>) => void;
  previewEnabled?: boolean;
  transformBeforeSave?: (data: any) => any;
}

export const useEditableTable = <TRecord extends BaseModel>({
  crud,
  extraProps = {},
  extraFormProps = {},
  filterProps = {},
  extraInvalidate = [],
  onData = () => {},
  previewEnabled = false,
  transformBeforeSave = (data: any) => toFormData(data),
}: EditableTableProps<TRecord>) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [editItem, setEditItem] = useState<TRecord | null>(null);
  const [createdItems, setCreatedItems] = useState<TRecord[]>([]);
  const { message } = App.useApp();

  const handleSetEditItem = (record: TRecord) => {
    setEditItem(record);
    form.setFieldsValue(record);
  };

  const filters = {
    ...extraProps,
    ...filterProps,
  };

  useEffect(() => {
    setEditItem(null);
    setCreatedItems([]);
  }, [JSON.stringify(filterProps)]);

  const { data, isLoading } = useQuery(
    [crud.key, filters],
    async () => {
      const result = await crud.list(filters);
      onData?.(result);
      return result;
    },
    {
      keepPreviousData: true,
    }
  );

  const quantity = Form.useWatch([], form);
  const formStringifyProps = {
    quantity,
  };

  const { data: previewData } = useQuery(
    [crud.key, formStringifyProps],
    async () => {
      if (crud.preview === undefined) throw new Error("No preview method");
      const params = {
        ...extraProps,
        ...form.getFieldsValue(),
      };
      const result = await crud.preview(params);
      return result.data;
    },
    {
      enabled: !!editItem && previewEnabled,
    }
  );

  const saveMutation = useMutation<ResponseBase<TRecord>, any, TRecord>(
    (formObj: any) => {
      const formData = transformBeforeSave({ ...formObj, ...extraProps });
      return editItem?.id
        ? crud.update(editItem.id!, formData)
        : crud.create(formData);
    },
    {
      onError: (e) => {
        message.error(e.response.data.message);
      },
      onSuccess: (response) => {
        message.success(response.message);
        queryClient.invalidateQueries(crud.key);
        queryClient.invalidateQueries(extraInvalidate);
        if (callbackRef.current) {
          callbackRef.current();
        } else {
          form.resetFields();
          setEditItem(null);
        }
        setCreatedItems((prev) => [response.data, ...prev]);
      },
    }
  );

  const { askToDelete } = useDeleteConfirm({
    crud: crud,
    onSuccess: (response, id) => {
      setCreatedItems((prev) => prev?.filter((item) => item.id !== id));
      queryClient.invalidateQueries(extraInvalidate);
    },
  });

  const isEditing = (recordId: string) => recordId === editItem?.id;

  const formProps = {
    form,
    initialValues: { ...extraFormProps },
    onFinish: (formObj: any) => {
      // console.log("!!! formObj", formObj);
      saveMutation.mutate(formObj);
    },
  };

  const responseItems = (data?.items ?? []).map((item, i) => ({
    ...item,
    index: i + 1,
  }));

  const editableItem = editItem?.id === null ? [editItem] : [];
  const createdItemsFiltered = createdItems.filter(
    (ci) => !responseItems.find((ri) => ri.id === ci.id)
  );
  const dataSource = [
    ...editableItem,
    ...createdItemsFiltered,
    ...responseItems,
  ].map((item) =>
    editItem?.id === item.id ? { ...item, ...(previewData || {}) } : item
  );

  const tableProps = {
    dataSource,
    rowKey: (record: TRecord) => record.id,
  };

  const callbackRef = useRef<any>(undefined);

  const setFormItem = (record: any) => {
    form.resetFields();
    handleSetEditItem(record);
    callbackRef.current = undefined;
  };

  const editButtonProps = (record: TRecord | any) => ({
    onClick: () => {
      if (editItem && !record.id) {
        callbackRef.current = () => {
          const item = record;
          setFormItem(item);
        };
        form.submit();
      } else {
        form.resetFields();
        handleSetEditItem(record);
      }
    },
  });

  const cancelButtonProps = {
    onClick: () => {
      setEditItem(null);
      form.resetFields();
      form.setFieldsValue({});
    },
  };

  const saveButtonProps = {
    onClick: () => form.submit(),
  };

  const deleteButtonProps = (id: string) => ({
    onClick: () => {
      askToDelete(id);
    },
  });

  return {
    data,
    tableProps,
    isLoading,
    editItem,
    isEditing,
    formProps,
    editButtonProps,
    cancelButtonProps,
    saveButtonProps,
    deleteButtonProps,
  };
};
