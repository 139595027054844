import React from "react";
import {
  BrowserRouter,
  Link,
  NavLink,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClientInit } from "utils/queryClientInit";
import CookieConsent from "react-cookie-consent";

import "./App.css";
import { Dashboard } from "pages/Dashboard";
import { Orders } from "pages/Orders";
import { Divider, Flex, Layout, Space, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { LoginPage } from "pages/Login";
import { AuthProvider, useAuthContext } from "AuthProvider";
import { ProtectedRoute } from "components/ProtectedRoute";
import { RegisterPage } from "pages/Register";
import { SignOutPage } from "pages/SignOut";
import { LandingPage } from "pages/LandingPage";
import { UserListPage } from "pages/User/UserListPage";
import { LeftNavigation } from "components/LeftNavigation";
import { PageListPage } from "pages/Page/PageListPage";
import { PageEditPage } from "pages/Page/PageEditPage";
import { UserEditPage } from "pages/User/UserEditPage";
import { SubcategoryListPage } from "pages/Subcategory/SubcategoryListPage";
import { SubcategoryEditPage } from "pages/Subcategory/SubcategoryEditPage";
import { SectionListPage } from "pages/Section/SectionListPage";
import { SectionEditPage } from "pages/Section/SectionEditPage";
import { AuctionListPage } from "pages/Auction/AuctionListPage";
import { AuctionEditPage } from "pages/Auction/AuctionEditPage";
import { CurrencyListPage } from "pages/Currency/CurrencyListPage";
import { ProfileEditPage } from "pages/Profile/ProfileEdit";
import { CurrencyEditPage } from "pages/Currency/CurrencyEditPage";
import { TaskListPage } from "pages/Task/TaskListPage";
import { TaskEditPage } from "pages/Task/TaskEditPage";
import { EmailCodeVerificationPage } from "pages/EmailCodeVerification";
import { AdminListPage } from "pages/Admins/AdminListPage";
import { AdminEditPage } from "pages/Admins/AdminEditPage";
import { GenericPage } from "pages/GenericPage";
import { TopNavigation } from "components/TopNavigation";
import { ContractListPage } from "pages/Contract/ContractListPage";
import { ContractEditPage } from "pages/Contract/ContractEditPage";
import { PaymentListPage } from "pages/Payment/PaymentListPage";
import { PaymentEditPage } from "pages/Payment/PaymentEditPage";
import { NotFoundPage } from "pages/NotFound";
import { SkillListPage } from "pages/Skill/SkillListPage";
import { SkillEditPage } from "pages/Skill/SkillEditPage";
import { MyProposalListPage } from "pages/MyProposal/MyProposalListPage";
import { MyProposalEditPage } from "pages/MyProposal/MyProposalEditPage";
import { ProposalListPage } from "pages/Proposal/ProposalListPage";
import { ProposalEditPage } from "pages/Proposal/ProposalEditPage";

export const MyRoutes = ({ location }: { location?: string }) => {
  const { hasAdminRole } = useAuthContext();
  return (
    <Routes location={location}>
      <Route path="/" element={<MyLayout />}>
        <Route index element={<LandingPage />} />
        <Route
          path="terms-and-conditions"
          element={<GenericPage code="terms-and-conditions" />}
        />
        <Route
          path="privacy-policy"
          element={<GenericPage code="privacy-policy" />}
        />
        <Route path="about-us" element={<GenericPage code="about-us" />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="sign-out" element={<SignOutPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="verify" element={<EmailCodeVerificationPage />} />
      </Route>
      <Route
        path="/platform"
        element={
          <ProtectedRoute>
            {/* WTF MyLayout ? MyLeftLayout ? */}
            <MyLayout showLeftNavigation />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            hasAdminRole ? <Dashboard /> : <Navigate to="current-auctions" />
          }
        />
        <Route path="orders" element={<Orders />} />

        <Route path="admins" element={<AdminListPage />} />
        <Route path="admins/create" element={<AdminEditPage />} />
        <Route path="admins/:adminId" element={<AdminEditPage />} />

        <Route path="profile" element={<ProfileEditPage />} />

        <Route path="users" element={<UserListPage />} />
        <Route path="users/create" element={<UserEditPage />} />
        <Route path="users/:userId" element={<UserEditPage />} />

        {/* My Proposal */}
        <Route path="my-proposals" element={<MyProposalListPage />} />
        <Route path="my-proposals/create" element={<MyProposalEditPage />} />
        <Route
          path="my-proposals/:proposalId"
          element={<MyProposalEditPage />}
        />

        {/* Proposal */}
        <Route path="proposals" element={<ProposalListPage />} />
        <Route path="proposals/:proposalId" element={<ProposalEditPage />} />

        {/* Skill */}
        <Route path="skills" element={<SkillListPage />} />
        <Route path="skills/create" element={<SkillEditPage />} />
        <Route path="skills/:skillId" element={<SkillEditPage />} />

        {/* Task */}
        <Route path="tasks" element={<TaskListPage />} />
        <Route path="tasks/create" element={<TaskEditPage />} />
        <Route path="tasks/:taskId" element={<TaskEditPage />} />

        {/* Contract */}
        <Route path="contracts" element={<ContractListPage />} />
        <Route path="contracts/create" element={<ContractEditPage />} />
        <Route path="contracts/:contractId" element={<ContractEditPage />} />

        {/* Payment */}
        <Route path="payments" element={<PaymentListPage />} />
        <Route path="payments/create" element={<PaymentEditPage />} />
        <Route path="payments/:paymentId" element={<PaymentEditPage />} />

        {/* Page */}
        <Route path="pages" element={<PageListPage />} />
        <Route path="pages/create" element={<PageEditPage />} />
        <Route path="pages/:pageId" element={<PageEditPage />} />
        <Route path="subcategories" element={<SubcategoryListPage />} />
        <Route path="subcategories/create" element={<SubcategoryEditPage />} />
        <Route
          path="subcategories/:subcategoryId"
          element={<SubcategoryEditPage />}
        />
        <Route path="categories" element={<SectionListPage />} />
        <Route path="categories/create" element={<SectionEditPage />} />
        <Route path="categories/:sectionId" element={<SectionEditPage />} />
        <Route
          path="closed-auctions"
          element={
            <AuctionListPage
              key="AuctionClosed"
              title="Closed Auctions"
              pageFilters={{ state: "closed" }}
              type="closed"
            />
          }
        />
        <Route
          path="current-auctions"
          element={
            <AuctionListPage
              key="AuctionCurrent"
              title="Current Auctions"
              pageFilters={{ state: "active" }}
              type="current"
            />
          }
        />
        <Route
          path="auctions"
          element={
            <AuctionListPage key="AuctionAll" title="Auctions" type="all" />
          }
        />
        <Route path="auctions/create" element={<AuctionEditPage />} />
        <Route path="auctions/:auctionId" element={<AuctionEditPage />} />
        <Route path="currencies" element={<CurrencyListPage />} />
        <Route path="currencies/create" element={<CurrencyEditPage />} />
        <Route path="currencies/:currencyId" element={<CurrencyEditPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClientInit}>
        <AuthProvider>
          <MyRoutes />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const MyLayout = ({
  showLeftNavigation = false,
}: {
  showLeftNavigation?: boolean;
}) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const showLeftNavContainerStyle = showLeftNavigation
    ? {
        padding: "24px 24px 0 24px",
        margin: 0,
        minHeight: 280,
      }
    : {};

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="consentStatus"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        <p>
          Your privacy is important to us. We do not collect any information
          from users who are not registered or logged in on our website. We also
          do not use cookies or other tracking technologies.
        </p>
        <p>
          When you register and log in to use our website, you will need to
          provide certain information and consent to its processing. For
          registered users, we use local storage to enhance your experience and
          ensure the website performs optimally.
        </p>
        <p>
          For more information please visit our{" "}
          <Link to={"/privacy-policy"}>Privacy Policy</Link>.
        </p>
      </CookieConsent>
      <TopNavigation />
      <Layout>
        {showLeftNavigation && (
          <Sider width={200} style={{ background: colorBgContainer }}>
            <LeftNavigation />
          </Sider>
        )}
        <Layout
          style={{ backgroundColor: showLeftNavigation ? "unset" : "white" }}
        >
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              ...showLeftNavContainerStyle,
            }}
          >
            <div>
              <Outlet />
            </div>
            <MyFooter
              style={showLeftNavigation ? {} : { padding: "24px 24px 0 24px" }}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const MyFooter = ({ style }: any) => {
  return (
    <div
      style={{
        fontSize: "14px",
        margin: "20px 0 30px 0",
        color: "grey",
        ...style,
      }}
    >
      <Divider style={{ margin: "30px 0" }} />
      <div>
        <Space direction="vertical">
          <Flex>
            <NavLink to={"/terms-and-conditions"} className={"footer-nav-link"}>
              terms & conditions
            </NavLink>
          </Flex>
          <Flex>
            <NavLink to={"/privacy-policy"} className={"footer-nav-link"}>
              privacy policy
            </NavLink>
          </Flex>
        </Space>
      </div>
      <div
        style={{
          color: "grey",
          fontSize: "12px",
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        © 2015-2024 All Rights Reserved
      </div>
    </div>
  );
};
