import { Crud } from "hooks/useCrud";

export interface Props {
  code: string;
}

export const GenericPage = ({ code }: Props) => {
  const { data: resp, isLoading } = Crud.page.useList({
    extraParams: { code },
  });
  const data = resp?.items?.[0];
  const htmlString = data?.content || "";
  if (isLoading) return null;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        fontSize: "1.2em",
      }}
    >
      <div style={{ maxWidth: "908px", width: "908px", padding: "24px" }}>
        <h1>{data?.title}</h1>
        <HtmlContent htmlString={htmlString} />
      </div>
    </div>
  );
};

export function HtmlContent({ htmlString }: { htmlString: string }) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}
