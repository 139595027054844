import { Card, Form, Input } from "antd";
import { Subcategory, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";
import { LinkToSection } from "components/Links/LinkToSection";
import { AjaxSelect } from "components/AjaxSelect";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";

export const SubcategoryEditPage = () => {
  const { subcategoryId: id } = useParams<{ subcategoryId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Subcategory>({
    id,
    isEdit: !id,
    endpoint: api.subcategory,
    onAfterCreateNavigateTo: (data) => `/platform/subcategories/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/subcategories",
  });

  const data = respData || {};

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? <>{data?.name}</> : "Create subcategory"}
          actions={actions}
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Name"
              name={field("name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.name, <Input />)}
            </FormColInput>
            <FormColInput
              label="Category"
              name={field("section_id")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                <LinkToSection {...data.section} />,
                <AjaxSelect endpoint={api.category} />
              )}
            </FormColInput>
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
