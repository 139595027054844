import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Divider,
  Row,
  Col,
  Card,
  Alert,
  Typography,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import logo from "./../logo.svg";
import { useLocalStorage } from "../hooks/useLocalStorage";
import type { AuthProps, AuthResponse, ResponseBase } from "../api";
import { REACT_APP_API } from "../env";
import { useAuthContext } from "../AuthProvider";
import type { AxiosResponse } from "axios";

export interface FormData {
  remember: boolean;
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const [remember, setRemember] = useLocalStorage("rememberMe", false);
  const { token, user, setToken } = useAuthContext();
  const navigate = useNavigate();
  const [email, setEmail] = useLocalStorage("email", "");
  const [authFailed, setAuthFailed] = useState(false);

  const loginMutation = useMutation<
    AxiosResponse<ResponseBase<AuthResponse>>,
    any,
    AuthProps
  >(
    (formData) =>
      axios.post(`${REACT_APP_API}/login`, null, { params: formData }),
    {
      onError: (e) => {
        setAuthFailed(true);
      },
      onSuccess: (response) => {
        setAuthFailed(false);
        setToken(response.data.data.token);
      },
    }
  );

  const handleRememberMe = (e: CheckboxChangeEvent) => {
    setRemember(e.target.checked);
  };

  const onFinish = ({ remember, email, password }: FormData) => {
    setRemember(remember);
    if (remember) {
      setEmail(email);
    } else {
      setEmail("");
    }
    loginMutation.mutate({ email, password });
  };

  if (user && token) {
    setTimeout(() => {
      navigate("/platform");
    }, 0);
  }

  return (
    <Row
      justify="center"
      style={{
        minHeight: "100vh",
        alignItems: "start",
        backgroundColor: "var(--tme-grey-6)",
      }}
    >
      <Col style={{ display: "flex", padding: "22px 12px" }}>
        <Card
          style={{ width: "400px", marginTop: "calc((100vh - 620px) / 2)" }}
        >
          <Form
            name="basic"
            layout="vertical"
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember, email }}
            onFinish={onFinish}
            requiredMark={false}
            autoComplete="off"
          >
            <Row justify="center">
              <Col>
                <img
                  src="/fab-logo.png"
                  style={{ height: 80, margin: 16 }}
                  alt="logo"
                />
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  color: "#f62665",
                  fontWeight: 700,
                  fontSize: "20px",
                  fontFamily: "cursive",
                }}
              >
                Creators Platform
              </Col>
            </Row>
            <Divider plain={true}></Divider>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Login
            </Typography.Title>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Password required" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox onChange={handleRememberMe}>Remember me</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Login
              </Button>
              {authFailed && (
                <Alert
                  message="Incorrect email or password. Please try again."
                  type="error"
                  style={{ marginTop: "22px" }}
                  closable
                  onClose={() => setAuthFailed(false)}
                />
              )}
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
