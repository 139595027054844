import { FC } from "react";
import { Button, Checkbox, Popover } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/es/checkbox/Group";

export interface Item {
  key: string;
  title: string;
}

export interface ColumnSelectorProps {
  items: Item[];
  selected: string[];
  setItems: (data: string[]) => void;
}

export const ColumnSelector: FC<ColumnSelectorProps> = ({
  items,
  selected,
  setItems,
}) => {
  const cbItems = items.map((x) => ({
    label: x.title,
    value: x.key,
  }));
  // const [value, setValue] = useState(['service_kind'])

  const handleChange = (newValues: CheckboxValueType[]) => {
    setItems(newValues as string[]);
    // console.log('CHANGE: ', e)
  };

  const checkboxRender = () => {
    return (
      <div className={"column-selector"}>
        <Checkbox.Group
          options={cbItems}
          onChange={handleChange}
          value={selected}
        />
      </div>
    );
  };

  return (
    <Popover content={checkboxRender()} trigger="click" placement="bottomLeft">
      <Button icon={<SettingOutlined />}>
        {/* <span className='responsive-button-text'>
          Колонки
        </span> */}
      </Button>
    </Popover>
  );
};
