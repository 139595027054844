import { App } from "antd";
import { BaseModel, CrudOperations, ResponseBase, api } from "api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

interface useDeleteProps {
  onAfterDelete?: (response: ResponseBase<any>) => void;
  onAfterDeleteNavigateTo?: (response: ResponseBase<any>) => string;
}

interface useSaveProps {
  onAfterSuccess?: (response: ResponseBase<any>, variables: any) => void;
  showSuccessMessage?: boolean;
}

interface useListOptions {
  extraParams?: any;
  enabled?: boolean;
}

export const makeUseCrud = <T extends BaseModel>(crud: CrudOperations<T>) => ({
  useGet: (id?: number | string | null) => {
    let _id = convertIdToString(id);
    return useQuery(
      [crud.key, _id],
      () => {
        return crud.get(_id!);
      },
      { enabled: !!id }
    );
  },
  useList: (options?: useListOptions) => {
    const listKey = [crud.key, options?.extraParams];
    if (options?.extraParams) {
      listKey.push(options.extraParams);
    }
    return useQuery(listKey, () => {
      return crud.list(options?.extraParams);
    }, {
      enabled: options?.enabled
    });
  },
  useAjaxList: (options?: useListOptions) => {
    const listKey = [crud.key, options?.extraParams];
    if (options?.extraParams) {
      listKey.push(options.extraParams);
    }
    return useQuery(listKey, () => {
      return crud.ajaxList(options?.extraParams);
    }, {
      enabled: options?.enabled
    });
  },
  useSave: (optionProps?: useSaveProps) => {
    const options = {
      showSuccessMessage: true,
      ...optionProps,
    }
    const queryClient = useQueryClient();
    const { message } = App.useApp();
    const { mutate, ...rest } = useMutation<ResponseBase<T>, any, FormData>(
      (data) => {
        const id = data.get("id") as string;
        return !!id ? crud.update(id, data) : crud.create(data)
      },
      {
        onError: (e) => {
          message.error(e.response.data.message);
        },
        onSuccess: (response, variables) => {
          queryClient.invalidateQueries(crud.key);
          options?.showSuccessMessage && message.success(response.message);
          options?.onAfterSuccess?.(response, variables);
        },
      }
    );
    return { save: mutate, ...rest }
  },
  useDelete: (options?: useDeleteProps) => {
    const { message } = App.useApp();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { mutate, ...rest } = useMutation<ResponseBase<any>, any, string>(
      (id) => crud.delete(id),
      {
        onError: (e) => {
          message.error(e.response.data.message);
        },
        onSuccess: (response, id) => {
          queryClient.invalidateQueries(crud.key);
          message.success(response.message); // todo: make it dynamic based on response
          options?.onAfterDelete?.(response);
          const navTo = options?.onAfterDeleteNavigateTo?.(response);
          if (navTo) {
            navigate(navTo);
          }
        },
      }
    );
    return {
      del: mutate,
      ...rest
    };
  }
})

export const Crud = {
  section: makeUseCrud(api.category),
  subcategory: makeUseCrud(api.subcategory),
  user: makeUseCrud(api.user),
  task: makeUseCrud(api.task),
  taskFile: makeUseCrud(api.taskFile),
  auction: makeUseCrud(api.auction),
  bid: makeUseCrud(api.bid),
  page: makeUseCrud(api.page),
}


function convertIdToString(id?: number | string | null): string {
  if (id === null || id === undefined) {
    return ''; // You can change this to a different default value if needed
  }

  return String(id);
}

export type UseCrud = ReturnType<typeof makeUseCrud>;