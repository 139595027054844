import { Card } from "antd";
import { Skill, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";

export const SkillListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/skills/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Skills"
        actions={
          <ButtonCreateNavigate link="/platform/skills/create">
            Skill
          </ButtonCreateNavigate>
        }
      />
      <DataTable<Skill>
        endpoint={api.skill}
        columns={columns as any}
        tableId={"SkillListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sortIndex: "name",
    sorter: true,
  },
];
