import {
  formatDate,
  formatDateTime,
  formatPrice,
  formatQuantity,
} from "utils/format";

export const NoWrap = ({ value }: { value?: string | React.ReactElement }) => {
  if (!value) return null;
  return <span style={{ whiteSpace: "nowrap" }}>{value}</span>;
};

export const renderPrice = (
  value: number | string | undefined,
  currency?: string
) => {
  if (value === undefined) return null;
  const price = formatPrice(value);
  const result = [price];
  if (currency) result.push(currency);
  return <NoWrap value={result.filter((x) => x).join(" ")} />;
};

export const renderQuantity = (value?: number | string) => {
  const price = formatQuantity(value);
  return <NoWrap value={price} />;
};

export const renderDate = (value?: string) => {
  const price = formatDate(value);
  return <NoWrap value={price} />;
};

export const renderDateTime = (value?: string) => {
  const price = formatDateTime(value);
  return <NoWrap value={price} />;
};

export const renderNoWrap = (value?: string) => {
  return <NoWrap value={value} />;
};
