import { api } from "api";
import {
  FileTextOutlined,
  DollarOutlined,
  ClockCircleOutlined,
  StopOutlined,
  WarningOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { GenerateStatuses } from "./stateStepUtils";

const iconStyle = { paddingRight: "6px" };

export const BidState = GenerateStatuses({
  endpoint: api.bid,
  options: [
    {
      value: "draft",
      label: "Draft",
      icon: <FileTextOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "active",
      label: "Active",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
    },
    {
      value: "won",
      label: "Won",
      icon: <DollarOutlined style={{ ...iconStyle, color: "green" }} />,
    },
    {
      value: "lost",
      label: "Lost",
      icon: <StopOutlined style={{ ...iconStyle, color: "red" }} />,
    },
    {
      value: "cancelled",
      label: "Cancelled",
      icon: <CloseOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "disqualified",
      label: "Disqualified",
      icon: <WarningOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
  ],
});
