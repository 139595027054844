import { api } from "api";
import { useQuery } from "react-query";

export interface Props {
  id?: string;
  isMe: boolean;
}

export const useGetUserOrMe = ({ id, isMe }: Props) => {
  const meResult = useQuery([api.me.key], () => api.me.get(), {
    enabled: isMe,
  });
  const userResult = useQuery([api.user.key, id], () => api.user.get(id!), {
    enabled: !isMe && !!id,
  });
  return isMe ? meResult : userResult;
};
