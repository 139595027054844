import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ButtonProps } from "antd/lib/button";

export const AddButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button {...props}>
      <PlusOutlined />
      {children}
    </Button>
  );
};
