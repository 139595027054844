import { Breadcrumb, Card, Form, Input, InputNumber } from "antd";
import { Currency, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { Link, useParams } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";

export const CurrencyEditPage = () => {
  const { currencyId: id } = useParams<{ currencyId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Currency>({
    id,
    isEdit: !id,
    endpoint: api.currency,
    onAfterCreateNavigateTo: (data) => `/platform/currencies/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/currencies",
  });

  const data = respData || {};

  return (
    <>
      <Breadcrumbs
        items={[
          {
            title: "Currencies",
            to: "/platform/currencies",
          },
          {
            title: "Currency",
          },
        ]}
      />
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? data.name : "Create currency"}
          actions={
            <>
              {actions}
              {/* <ActivityLog id={id} modelMeta={bankAccountMeta} />
              <DeleteConfirmButton
                id={id}
                crud={api.carWork}
                onSuccessNavigateTo="/car-works"
              /> */}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Code"
              name={field("code")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.code, <Input />)}
            </FormColInput>
            <FormColInput
              label="Name"
              name={field("name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.name, <Input />)}
            </FormColInput>
            <FormColInput
              label="Symbol"
              name={field("symbol")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.symbol, <Input />)}
            </FormColInput>
            <FormColInput
              label="Decimals"
              name={field("decimals")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.decimals, <InputNumber min={0} max={4} />)}
            </FormColInput>
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
