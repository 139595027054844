import { SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ButtonProps } from "antd/lib/button";

export const SaveButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button type="primary" {...props}>
      <SaveOutlined />
      {children}
    </Button>
  );
};
