import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ButtonProps } from "antd/lib/button";

export const CancelButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button {...props}>
      <CloseOutlined />
      {children}
    </Button>
  );
};
