import { UploadOutlined } from "@ant-design/icons";
import { App, Button, Space } from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useState } from "react";
import { UploadCustom } from "components/UploadCustom";
import { useQueryClient } from "react-query";
import { Image } from "antd";
import { useGetUserOrMe } from "./useGetUserOrMe";

export interface Props {
  id: string;
  isMe: boolean;
}

export const UserTabAvatar: React.FC<Props> = ({ id, isMe }) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { data: resp } = useGetUserOrMe({ id, isMe });
  const user = resp?.data;

  const [file, setFile] = useState<any>(null);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.fileList[0]) {
      setFile(info.fileList[0]);
    } else {
      setFile(null);
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("avatar", file.originFileObj);
    const endpoint = isMe ? api.me.updateUserAvatar : api.user.updateUserAvatar;
    endpoint(id, formData)
      .then((response) => {
        setFile(null);
        queryClient.invalidateQueries([api.user.key]);
        queryClient.invalidateQueries([api.me.key]);
        message.success(response.message);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <TitleWithActions title="Avatar" size="middle" />
      <Space direction="vertical" size={8}>
        <Image style={{ height: 240 }} src={user?.avatar_url} />
        <UploadCustom onChange={handleChange}>
          <Button icon={<UploadOutlined />}>Choose file</Button>
        </UploadCustom>
        {file && (
          <Button
            type="primary"
            htmlType="button"
            onClick={handleUpload}
            style={{ marginTop: "5px" }}
          >
            Upload
          </Button>
        )}
      </Space>
    </>
  );
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
