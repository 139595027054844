import { Col, Form, FormItemProps, Row } from "antd";
import type { FormProps } from "antd";

export interface Props {
  children: React.ReactNode;
}

// must be wrapped in <Row gutter={30}>...</Row>

export const ColInput: React.FC<Props> = ({ children }) => {
  return (
    <Col span={24} sm={24} lg={12} xxl={8}>
      {children}
    </Col>
  );
};

export const ColInput2: React.FC<Props> = ({ children }) => {
  return (
    <Col span={24} sm={24} lg={24} xxl={16}>
      {children}
    </Col>
  );
};

export const FormColInput: React.FC<FormItemProps> = ({
  children,
  ...props
}) => (
  <Col span={24} sm={24} lg={12} xxl={8}>
    <Form.Item
      labelCol={{ sm: 8, lg: 8, xxl: 10, className: "form-label-multi" }}
      labelAlign="left"
      {...props}
    >
      {children}
    </Form.Item>
  </Col>
);
export const FormColInput2: React.FC<FormItemProps> = ({
  children,
  ...props
}) => (
  <Col span={24} sm={24} lg={24} xxl={24} className="form-col-full-width">
    <Form.Item
      labelCol={{ className: "form-label-full-width" }}
      labelAlign="left"
      {...props}
    >
      {children}
    </Form.Item>
  </Col>
);

export type MultiColumnFormProps = {
  children: React.ReactNode;
} & FormProps;

export const MultiColumnForm: React.FC<MultiColumnFormProps> = ({
  children,
  ...props
}) => {
  return (
    <Form {...props}>
      <Row gutter={30}>{children}</Row>
    </Form>
  );
};
