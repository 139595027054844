import { api } from "api";
import {
  CheckOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { GenerateStatuses } from "./stateStepUtils";

const iconStyle = { paddingRight: "6px" };

export const ContractState = GenerateStatuses({
  endpoint: api.contract,
  options: [
    {
      value: "draft",
      label: "Draft",
      icon: <FileTextOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "scheduled",
      label: "Ready",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
    },
    {
      value: "active",
      label: "Signed",
      icon: <CheckOutlined style={{ ...iconStyle, color: "green" }} />,
    },
  ],
});
