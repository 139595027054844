import { Link } from "react-router-dom";

export interface Props {
  name?: string | undefined;
  id?: string | undefined;
}

export const LinkToContract: React.FC<Props> = ({ name, id }) => {
  if (!id) return null;
  return (
    <Link style={{ whiteSpace: "nowrap" }} to={`/platform/contracts/${id}`}>
      CONTRACT-{id}
    </Link>
  );
};
