import { useAuthContext } from "AuthProvider";
import { Card } from "antd";
import { Task, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { LinkToTask } from "components/Links/LinkToTask";
import { TaskState } from "components/State/TaskState";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";

export const TaskListPage = () => {
  const navigate = useNavigate();
  const { hasAdminRole } = useAuthContext();

  const handleEdit = (id: string) => {
    navigate(`/platform/tasks/${id}`);
  };

  // const taskActions = hasAdminRole ? (
  //   <ButtonCreateNavigate link="/platform/tasks/create">
  //     Task
  //   </ButtonCreateNavigate>
  // ) : undefined;

  return (
    <Card>
      <TitleWithActions title="Tasks" />
      <DataTable<Task>
        endpoint={api.task}
        columns={columns}
        tableId={"TaskListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: ["auction", "name"],
    key: "name",
    sorter: true,
    render: (_: string, record: Task) => (
      <LinkToTask id={record.id} name={record.auction?.name} />
    ),
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    sorter: true,
    render: TaskState.render,
  },
];
