import { Card } from "antd";
import { Subcategory, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { LinkToCategory } from "components/Links/LinkToCategory";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";

export const SubcategoryListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/subcategories/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Subcategories"
        actions={
          <ButtonCreateNavigate link="/platform/subcategories/create">
            Subcategory
          </ButtonCreateNavigate>
        }
      />
      <DataTable<Subcategory>
        endpoint={api.subcategory}
        columns={columns}
        tableId={"SubcategoryListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Category",
    dataIndex: "section",
    key: "section",
    sorter: true,
    render: (category: any) => <LinkToCategory {...category} />,
  },
];
