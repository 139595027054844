import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";

export const formatPrice = (value?: number | string): string => {
  if (value === undefined || isNaN(value as any)) return "";
  return Number(value)
    .toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
};

export const formatDateTime = (value?: string): string => {
  if (!value) return "";
  return dayjs(value).format("YYYY-MM-DD - HH:mm");
};

export const formatDate = (value?: string): string => {
  if (!value) return "";
  return dayjs(value).format("YYYY-MM-DD");
};

export const formatQuantity = (value?: number | string): string => {
  if (value === undefined) return "";
  return Number(value)
    .toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
};

export const disabledBackDate: RangePickerProps["disabledDate"] = (
  current: any
) => current && current < dayjs().startOf("day");
