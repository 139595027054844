import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";

export interface Props {
  children: React.ReactElement;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
  const { token } = useAuthContext();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};
