import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  Row,
  Col,
  Card,
  Alert,
  Typography,
  App,
} from "antd";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuid } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";

import type { AuthResponse, ResponseBase } from "../api";
import { REACT_APP_API } from "../env";
import { useAuthContext } from "../AuthProvider";
import type { AxiosResponse } from "axios";

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

export interface MutationProps {
  code: string;
}

export const EmailCodeVerificationPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Get the value of the 'code' parameter
  const code = queryParams.get("code");
  const initialCodeSent = useRef(false);

  const [token, setToken] = useState<string | null>(null);
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const [authFailed, setAuthFailed] = useState(false);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { message } = App.useApp();

  const keys = useMemo(() => {
    return {
      code: uuid(),
    };
  }, []);

  const mutation = useMutation<
    AxiosResponse<ResponseBase<AuthResponse>>,
    any,
    MutationProps
  >(
    (formData) =>
      axios.post(`${REACT_APP_API}/verify`, null, { params: formData }),
    {
      onError: (e) => {
        message.error(e.response.data.message);
        const keys = Object.keys(e.response.data.data);
        let em = "";
        for (const key of keys) {
          const errors = e.response.data.data[key];
          em += key + ": " + errors.join(", ");
        }
        setErrorMessage(em);
        setAuthFailed(true);
      },
      onSuccess: (response) => {
        setAuthFailed(false);
        // Keep invalidation as it is
        queryClient.invalidateQueries("getCurrentUser");
        setToken(response.data.data.token);
      },
    }
  );

  const getCurrentUser = async (): Promise<any> => {
    const { data } = await axios.get(`${REACT_APP_API}/user`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  useQuery(["getCurrentUser"], getCurrentUser, {
    enabled: !!token,
    onSuccess: (data) => {
      login(token!, data);
      navigate("/platform");
    },
  });

  const onFinish = ({ code }: MutationProps) => {
    mutation.mutate({ code });
  };

  useEffect(() => {
    if (code && initialCodeSent?.current === false) {
      initialCodeSent.current = true;
      onFinish({ code });
    }
  }, [code]);

  return (
    <Row
      justify="center"
      style={{
        minHeight: "100vh",
        alignItems: "start",
        backgroundColor: "var(--tme-grey-6)",
      }}
    >
      <Col style={{ display: "flex", padding: "22px 12px" }}>
        <Card
          style={{ width: "400px", marginTop: "calc((100vh - 620px) / 2)" }}
        >
          <Form
            name="basic"
            layout="vertical"
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{ code }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            autoComplete="off"
          >
            <Row justify="center">
              <Col>
                <img
                  src="/fab-logo.png"
                  style={{ height: 80, margin: 16 }}
                  alt="logo"
                />
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  color: "#f62665",
                  fontWeight: 700,
                  fontSize: "20px",
                  fontFamily: "cursive",
                }}
              >
                Creators Platform
              </Col>
            </Row>
            <Divider plain={true}></Divider>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Verification
            </Typography.Title>
            <Form.Item
              label="Paste the code from verification email"
              name="code"
              rules={[{ required: true, message: "Code is required" }]}
            >
              <Input autoComplete={keys.code} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Register
              </Button>
              {authFailed && (
                <Alert
                  message={errorMessage}
                  type="error"
                  style={{ marginTop: "22px" }}
                  closable
                  onClose={() => {
                    setErrorMessage(null);
                    setAuthFailed(false);
                  }}
                />
              )}
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
