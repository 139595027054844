import { useAuthContext } from "AuthProvider";
import { Button, Form, Input } from "antd";
import { Task, api } from "api";
import { TaskState } from "components/State/TaskState";
import { TitleWithActions } from "components/TitleWithActions";
import { Crud } from "hooks/useCrud";
import { useLPForm } from "hooks/useLPForm";
import { useEffect, useLayoutEffect, useState } from "react";
import { useQueryClient } from "react-query";

export interface AuctionTabTaskWrapperProps {
  auctionId: string;
}

export const AuctionTabTaskWrapper = ({
  auctionId,
}: AuctionTabTaskWrapperProps) => {
  const tasksResp = Crud.task.useList({
    extraParams: { auction_id: auctionId, limit: 1 },
  });
  const task = tasksResp.data?.items?.[0];
  if (tasksResp.isLoading) return null;
  if (!task) {
    return <AuctionTabTask id={undefined} auctionId={auctionId} />;
  }
  return <AuctionTabTask id={task.id} auctionId={auctionId} />;
};

export interface AuctionTabTaskProps {
  id?: string;
  auctionId: string;
}

export const AuctionTabTask = ({ id, auctionId }: AuctionTabTaskProps) => {
  const [showPlaceholder, setShowPlaceholder] = useState(id ? false : true);
  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    setEditMode,
    actions,
    toFormData,
  } = useLPForm<Task>({
    id,
    isEdit: !id,
    endpoint: api.task,
    mustHandleErrors: false,
    onAfterDelete(data) {
      setShowPlaceholder(true);
      setEditMode();
    },
    // onAfterCreateNavigateTo: (data) => `/platform/tasks/${data.id}`,
    // onAfterDeleteNavigateTo: (data) => "/platform/tasks",
  });

  const data = respData || {};
  const { hasAdminRole } = useAuthContext();

  const handleFinish = (fd: any) => {
    const data = { ...fd, auction_id: auctionId };
    save(toFormData(data));
  };

  return (
    <>
      <TitleWithActions
        title="Task"
        actions={
          hasAdminRole && (
            <>
              {id && TaskState.render(data?.state)}
              {id && actions}
              {!id && !showPlaceholder && actions}
              {!id && showPlaceholder && (
                <Button
                  type="primary"
                  onClick={() => {
                    setShowPlaceholder(false);
                  }}
                >
                  Create task
                </Button>
              )}
              {!id && !showPlaceholder && (
                <Button
                  type="default"
                  onClick={() => {
                    setShowPlaceholder(true);
                  }}
                >
                  Cancel
                </Button>
              )}
            </>
          )
        }
      />
      {!id && showPlaceholder && <div>No task</div>}
      {!showPlaceholder && showForm && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          style={{ maxWidth: 852 }}
          initialValues={data}
          form={form}
          onFinish={handleFinish}
          autoComplete="off"
        >
          {hasAdminRole && data.s3_folder && (
            <Form.Item label="S3 Folder">{data.s3_folder}</Form.Item>
          )}
          <Form.Item
            label="Task link"
            name={field("link_to_document")}
            rules={[
              {
                required: true,
                message: "Please input a URL",
              },
              {
                validator: validateURL,
              },
            ]}
          >
            {viewOrEdit(
              <a
                style={{ wordWrap: "break-word" }}
                href={data.link_to_document}
                target="_blank"
                rel="noreferrer"
              >
                {data.link_to_document?.substring(0, 120)}
              </a>,
              <Input />
            )}
          </Form.Item>
        </Form>
      )}
      {/* </div> */}
    </>
  );
};

export const validateURL = (rule: any, value: any) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_=]*)?$",
    "i"
  ); // fragment locator
  if (!value || pattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter a valid URL"));
};
