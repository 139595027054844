import { Button, Flex, Input } from "antd";
import { useAvailableFilterOptions } from "../SmartFilterDialog";
import { useState } from "react";
import { FilterOption } from "api";
import { ConfiguredSmartFilter } from "../useSmartFilters";

export interface FilterProps {
  modelName: string;
  smartFilter: ConfiguredSmartFilter;
  handleChangeOperand: (operand: FilterOption) => void;
}

export const FilterStrictText = ({
  modelName,
  smartFilter,
  handleChangeOperand,
}: FilterProps) => {
  const [search, setSearch] = useState<string>();

  const { filterOptions } = useAvailableFilterOptions(
    modelName,
    smartFilter?.filter?.field,
    search
  );

  return (
    <Flex vertical gap="small">
      <Input
        placeholder={smartFilter?.filter!.label}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Flex vertical>
        {filterOptions.map((fo: any) => (
          <Button
            key={fo.id}
            type="text"
            onClick={() => {
              handleChangeOperand(fo);
            }}
            style={{ textAlign: "left" }}
          >
            {fo.name}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
