import { StepProps, Steps, Tooltip } from "antd";

export interface StatusStepProps extends StepProps {
  key?: string | string[];
}

export interface Props {
  state?: string;
  items: StatusStepProps[];
}

export const StateSteps: React.FC<Props> = ({ state = "", items }) => {
  let statusToSet: any = "finish";

  const steps = items.map((item, index): StatusStepProps => {
    if (statusToSet === "process") {
      statusToSet = "wait";
    }
    if (
      state === item.key ||
      (Array.isArray(item.key) && item.key?.includes(state))
    ) {
      statusToSet = "process";
    }
    return {
      ...item,
      description: undefined,
      icon: (
        <Tooltip
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0.3}
          title={item.description}
        >
          {item.icon}
        </Tooltip>
      ),
      status: statusToSet,
    };
  });
  return (
    <Steps items={steps} style={{ padding: "20px 0", color: "black" }}></Steps>
  );
};
