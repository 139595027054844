import { Col, Row, Space, Typography } from "antd";
import { Divider } from "./Divider";
import { RightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

export interface TitleWithActionsProps {
  title?: React.ReactNode | string;
  actions?: React.ReactNode;
  size?: "large" | "middle";
  divider?: "primary" | "secondary" | "none";
  toggleClass?: string;
}

export const TitleWithActions: React.FC<TitleWithActionsProps> = ({
  title,
  actions,
  size = "large",
  divider = "secondary",
  toggleClass,
}) => {
  const headerSize = getHeaderSize(size);

  const [isVisible, setIsVisible] = useState(true); // State to manage visibility

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle the visibility state
  };

  const style =
    size === "large"
      ? {
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          zIndex: 9,
        }
      : {};

  useEffect(() => {
    if (!toggleClass) return;
    const elements = document.getElementsByClassName(toggleClass);
    // @ts-ignore
    for (let element of elements) {
      element.style.display = isVisible ? "block" : "none"; // Apply CSS based on visibility state
    }
  }, [isVisible, toggleClass]);

  return (
    <>
      {/* @ts-ignore */}
      <Row style={style}>
        <Col style={{ flexGrow: 1 }}>
          {title && (
            <Typography.Title level={headerSize}>
              <span
                onClick={toggleVisibility} // Add click handler
              >
                {toggleClass && (
                  <Typography.Text>
                    <RightOutlined
                      style={{
                        color: "gray",
                        // fontSize: "10px",
                        marginRight: "12px",
                      }}
                      rotate={isVisible ? 90 : 0}
                    />
                  </Typography.Text>
                )}
                {title}
              </span>
            </Typography.Title>
          )}
        </Col>
        <Col
          style={{
            marginTop: title ? 18 : 0,
            flexGrow: 0,
          }}
        >
          <Space>{actions}</Space>
        </Col>
        {divider !== "none" && <Divider type={divider} />}
      </Row>
    </>
  );
};

const getHeaderSize = (size: "large" | "middle") => {
  if (size === "large") return 3;
  if (size === "middle") return 4;
  return 3;
};
