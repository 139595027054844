interface FormDataReturnType<T> {
  collect: (values: T, callbacks?: Callbacks<T>) => FormData;
}

export interface Callbacks<T> {
  [key: string]: (value: T[keyof T]) => any;
}

export const useFormData = <T extends {}>(): FormDataReturnType<T> => {
  const collect = (values: T, callbacks?: Callbacks<T>) => {
    const formData = new FormData();
    for (const key in values) {
      const valueToAppend =
        callbacks && callbacks[key] ? callbacks[key](values[key]) : values[key];
      formData.append(key, valueToAppend ?? ("" as any));
    }
    return formData;
  };

  return { collect };
};

export const toFormData = <T,>(values: T, callbacks?: Callbacks<T>) => {
  const formData = new FormData();
  for (const key in values) {
    const valueToAppend =
      callbacks && callbacks[key] ? callbacks[key](values[key]) : values[key];
    formData.append(key, valueToAppend ?? ("" as any));
  }
  return formData;
};