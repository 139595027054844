import { App, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { BaseModel, CrudOperations, ResponseBase } from "api";
import { MutateOptions, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export interface UseDeleteConfirmProps<T> {
  crud: Pick<CrudOperations<T>, "delete" | "key">;
  title?: string;
  content?: string;
  onSuccess?: (resp: any, variables: any) => void;
  onAfterDeleteNavigateTo?: (resp: any) => string;
}

export const useDeleteConfirm = <T extends BaseModel>({
  title = "Delete record",
  content = "This record will be removed. Are you sure?",
  crud,
  ...rest
}: UseDeleteConfirmProps<T>) => {
  const result = useConfirm({
    title,
    content,
    endpoint: crud.delete,
    queryKey: crud.key,
    ...rest,
  });
  return {
    ...result,
    askToDelete: result.askToPerform,
  };
};

export interface UseConfirmProps {
  endpoint: (id: string) => Promise<any>;
  queryKey: string | string[];
  title?: string;
  content?: string;
  onSuccess?: (resp: any, variables: any) => void;
  onAfterDeleteNavigateTo?: (resp: any) => string;
}

export const useConfirm = <T extends BaseModel>({
  endpoint,
  queryKey,
  title = "Delete record",
  content = "This record will be removed. Are you sure?",
  onSuccess,
  onAfterDeleteNavigateTo,
}: UseConfirmProps) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const navigate = useNavigate();

  // todo: use Crud.hooks
  const deleteMutation = useMutation<ResponseBase<any>, any, string>(
    async (id: string) => endpoint(id), // todo: ????
    {
      onError: (e) => {
        message.error(e.response.data.message);
      },
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries([queryKey]);
        message.success(response.message);
        onSuccess?.(response, variables);
        const navTo = onAfterDeleteNavigateTo?.(response);
        if (navTo) {
          navigate(navTo);
        }
      },
    }
  );

  const askToPerform = (
    id: string,
    options?: MutateOptions<ResponseBase<any>, any, string>
  ) => {
    Modal.confirm({
      title,
      icon: <ExclamationCircleFilled />,
      content,
      okText: "Confirm",
      okButtonProps: { danger: true, type: "primary" },
      cancelText: "Cancel",
      onOk() {
        deleteMutation.mutate(id, options);
      },
    });
  };

  return {
    askToPerform,
  };
};
