import { useAuthContext } from "AuthProvider";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const handleGoHome = () => {
    navigate(user ? "/platform" : "/");
  };

  return (
    <div>
      <Result
        title="404"
        icon={
          <img
            style={{ borderRadius: "50%", width: "300px" }}
            src="/404.jpg"
            alt="Img"
          />
        }
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={handleGoHome}>
            Back to Home
          </Button>
        }
      />
    </div>
  );
};
