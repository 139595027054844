import { useNavigate } from "react-router-dom";

export const useResponseErrorHandler = () => {
  const navigate = useNavigate();

  const handleError = (error: any) => {
    if (!error?.response) return;
    if (error.response.status === 404) {
      navigate("/404", { replace: true });
    }
  }

  return {
    handleError
  }
}