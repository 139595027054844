import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

interface CustomDatePickerProps {
  value?: string | null | undefined;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  bordered?: boolean;
  disabledDate?: (current: dayjs.Dayjs) => boolean;
}

export const LpDateTimePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  style,
  bordered,
  disabledDate,
}) => {
  const inputFormat = [
    "DD.MM.YYYY HH:mm:00",
    "DDMMYYYYHHmm00",
    "DD,MM,YYYY HH,mm,00",
  ];
  const handleDateChange = (
    date: dayjs.Dayjs,
    dateString: string | string[]
  ) => {
    if (onChange) {
      onChange(date ? date.format("YYYY-MM-DD HH:mm:ss") : "");
    }
  };

  return (
    <DatePicker
      showTime={{ minuteStep: 15 }}
      showSecond={false}
      format={inputFormat}
      value={value ? dayjs(value) : null}
      onChange={handleDateChange}
      style={style}
      bordered={bordered}
      disabledDate={disabledDate}
    />
  );
};
