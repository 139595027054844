import { Link } from "react-router-dom";

export interface Props {
  name?: string | undefined;
  id?: string | undefined;
}

export const LinkToCategory: React.FC<Props> = ({ name, id }) => {
  if (!name || !id) return null;
  return (
    <Link style={{ whiteSpace: "nowrap" }} to={`/platform/categories/${id}`}>
      {name}
    </Link>
  );
};
