import { Upload, UploadFile, type UploadProps } from "antd";
import { kmAxios } from "api";
import fileDownload from "js-file-download";
import styles from "./UploadCustom.module.scss";

export interface Props extends UploadProps {
  marginTop?: number;
  isEditMode?: boolean;
  maxCount?: number;
}

export const UploadCustom = ({
  isEditMode = true,
  maxCount = 1,
  children,
  ...props
}: Props) => {
  const handlePreview = (file: UploadFile) => {
    if (!file.url) return;
    kmAxios.get(file.url, { responseType: "blob" }).then((response) => {
      fileDownload(response.data, file.name);
    });
  };

  const beforeUpload = (file: UploadFile) => {
    return false;
  };

  return (
    <Upload
      className={isEditMode ? undefined : styles["upload-custom-no-button"]}
      onPreview={handlePreview}
      showUploadList={{
        showRemoveIcon: isEditMode,
      }}
      beforeUpload={beforeUpload}
      multiple={maxCount > 1}
      maxCount={maxCount}
      {...props}
      children={isEditMode && children}
    />
  );
};
