import { TablePaginationConfig } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { DEFAULT_TABLE_PARAMS, PaginatedResponse } from "api";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAccountSettings } from "./useAccountSettings";

export interface ListFilters {
  page?: number;
  perPage?: number;
  // filters?: Record<string, FilterValue>;
  search?: string;
  [key: string]: string | number | undefined;
}

export interface AntPagination {
  current: number;
  pageSize: number;
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export interface UseListFiltersProps {
  tableKey: string;
  pageSize?: number;
}

export const useListFilters = ({
  tableKey,
  pageSize = DEFAULT_TABLE_PARAMS.perPage,
}: UseListFiltersProps) => {
  const query = useQuery();

  const {
    visibleCols: preservedVisibleCols,
    saveVisibleCols,
    saveSortOrder,
    getSortOrder,
    forgetSortOrder,
  } = useAccountSettings();

  const [pagination, setPagination] = useState<AntPagination>({
    pageSize,
    current: 1,
  });
  const [search, setSearch] = useState<string>(query.get("search") || "");
  const [sorter, setSorter] = useState<
    SorterResult<any> | SorterResult<any>[] | undefined
  >(getSortOrder(tableKey));

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any, // Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    // console.log(pagination, filters, sorter);
    // const order = tableSortToApi(sorter);
    saveSortOrder(tableKey, JSON.stringify(sorter));
    // else forgetSortOrder(queryId);
    setSorter(sorter);
  };

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination({ current, pageSize });
  };

  const handleSearch = (search: string) => {
    setSearch(search);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const paginationProps = (paginatedResponse?: PaginatedResponse<any>) => ({
    ...pagination,
    total: paginatedResponse?.totalItems,
    onChange: handlePaginationChange,
  });

  let listFilters: ListFilters = {};
  if (pagination) {
    listFilters.page = pagination.current;
    listFilters.perPage = pagination.pageSize;
  }
  if (search) listFilters.search = search;
  const order = tableSortToApi(sorter);
  if (order) listFilters.order = order;

  return {
    onTableChange: handleTableChange,
    sorter,
    onSearch: handleSearch,
    search: search,
    paginationProps,
    listFilters,
  };
};

function tableSortToApi<TRecord>(
  sorter: SorterResult<TRecord> | SorterResult<TRecord>[] | undefined
) {
  if (!sorter || Array.isArray(sorter)) return null;
  if (!sorter.order || !sorter.columnKey) return null;
  const antSortToApi = {
    ascend: "asc",
    descend: "desc",
  };
  return [sorter.columnKey, antSortToApi[sorter.order]].join(":");
}
