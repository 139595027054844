import { Button, DatePicker, Flex, Input, Space } from "antd";
import { useAvailableFilterOptions } from "../SmartFilterDialog";
import { useState } from "react";
import { FilterOption } from "api";
import { ConfiguredSmartFilter } from "../useSmartFilters";
import dayjs from "dayjs";

export interface FilterProps {
  modelName: string;
  smartFilter: ConfiguredSmartFilter;
  handleChangeOperand: (operand: FilterOption) => void;
}

export const FilterDate = ({
  modelName,
  smartFilter,
  handleChangeOperand,
}: FilterProps) => {
  const filterOptions = [
    // todo: de-hardcode
    {
      name: "Сьогодні",
      id: "2023-11-29",
    },
    {
      name: "Вчора",
      id: "2023-11-28",
    },
  ];

  const handleDateChange = (date: any) => {
    if (!date) return;
    const value = date.format("YYYY-MM-DD");
    const name = date.format("DD/MM/YYYY");
    handleChangeOperand({
      id: value,
      name,
    });
  };

  const getInitial = (): dayjs.Dayjs | undefined => {
    if (!smartFilter?.operand?.id) return undefined;
    try {
      return dayjs(smartFilter?.operand?.id);
    } catch (e) {
      return undefined;
    }
  };

  const initial = getInitial();

  return (
    <Flex vertical gap="small">
      <Space.Compact>
        <DatePicker
          defaultValue={initial}
          placeholder={smartFilter?.filter!.label}
          onChange={handleDateChange}
        />
      </Space.Compact>
      <Flex vertical>
        {filterOptions.map((fo) => (
          <Button
            key={fo.id}
            type="text"
            onClick={() => {
              handleChangeOperand(fo);
            }}
            style={{ textAlign: "left" }}
          >
            {fo.name}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
