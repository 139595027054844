import { Input } from "antd";

export interface SearchInputProps {
  defaultValue?: string;
  onSearch: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  defaultValue,
  onSearch,
}) => (
  <Input.Search
    style={{ maxWidth: 350 }}
    placeholder="Text..."
    allowClear
    defaultValue={defaultValue}
    enterButton="Search"
    onSearch={onSearch}
  />
);
