import { MenuProps, Menu, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "./LeftNavigation";
import { Header } from "antd/es/layout/layout";
import { useAuthContext } from "AuthProvider";
import { User } from "api";

const UserLabel = ({ user }: { user?: User | null }) => (
  <>
    <Avatar src={user?.avatar_url}>
      <UserOutlined />
    </Avatar>
    <span className={"user-name"}>{user?.email}</span>
  </>
);

export const TopNavigation = () => {
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/platform/profile`);
  };

  const getSelectedKeys = (menuItem: (MenuItem | any)[]): string[] => {
    const items = menuItem.filter((x) => x.type !== "divider") as MenuItem[];
    const keys = items
      .filter((item) => item.url === pathname)
      .map((item) => item.key);
    const childKeys = items
      .filter((item) => item.children)
      .map((item) => getSelectedKeys(item.children!))
      .reduce((result, keys) => [...result, ...keys], []);
    return [...keys, ...childKeys];
  };

  const topPrivateMenu: any[] = [
    // MenuProps["items"]
    {
      key: "about-us",
      url: "/about-us",
      label: <Link to={"/about-us"}>About us</Link>,
    },
    {
      key: "user",
      label: <UserLabel user={user} />,
      children: [
        {
          key: "profile",
          label: "Profile",
          onClick: handleClick,
        },
        {
          type: "divider",
          style: {
            borderColor: "rgba(255,255,255,0.2)",
            margin: "0 20px",
          },
        },
        {
          key: "logout",
          label: <Link to={"/sign-out"}>Sign out</Link>,
        },
      ],
    },
  ];

  const topPublicMenu: MenuProps["items"] = [
    {
      key: "about us",
      label: <Link to={"/about-us"}>About us</Link>,
    },
    {
      key: "sign in",
      label: <Link to={"/login"}>Sign in</Link>,
    },
    {
      key: "register",
      label: <Link to={"/register"}>Register</Link>,
    },
  ];

  const selectedKeys = getSelectedKeys(user ? topPrivateMenu : topPublicMenu);
  return (
    <Header
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
        borderBottom: "2px solid rgb(255 168 194)",
        boxSizing: "content-box",
        // alignItems: "center",
      }}
    >
      <div className="header-logo">
        <Link to={user ? "/platform" : "/"} style={{ display: "flex" }}>
          <img style={{ height: "38px" }} src="/fab-logo.png" alt="logo" />
        </Link>
      </div>
      <div className="header-menu">
        <Menu
          theme="light"
          selectedKeys={selectedKeys}
          style={{ justifyContent: "flex-end", borderBottom: "none" }}
          // style={{ backgroundColor: "transparent" }}
          mode="horizontal"
          items={user ? topPrivateMenu : topPublicMenu}
        />
      </div>
    </Header>
  );
};
