import { Card, Form, Input } from "antd";
import { Page, api } from "api";
import { SubHeader } from "components/SubHeader";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";
import { HtmlContent } from "pages/GenericPage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  FormColInput,
  FormColInput2,
  MultiColumnForm,
} from "components/MultiColumnForm";

export const PageEditPage = () => {
  const { pageId: id } = useParams<{ pageId: string }>();
  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Page>({
    id,
    isEdit: !id,
    endpoint: api.page,
    onAfterCreateNavigateTo: (data) => `/platform/pages/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/pages",
  });

  const data = respData || {};

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={
            id ? (
              <>
                Page: <SubHeader>{data?.title}</SubHeader>
              </>
            ) : (
              "Create page"
            )
          }
          actions={
            <>
              {actions}
              {/* <ActivityLog id={id} modelMeta={bankAccountMeta} />
              <DeleteConfirmButton
                id={id}
                crud={api.carWork}
                onSuccessNavigateTo="/car-works"
              /> */}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Title"
              name={field("title")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.title, <Input />)}
            </FormColInput>
            <FormColInput
              label="Code"
              name={field("code")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.code, <Input />)}
            </FormColInput>
            <FormColInput
              label="Slug"
              name={field("slug")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.slug, <Input />)}
            </FormColInput>
            <FormColInput2
              label="Content"
              name={field("content")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                <HtmlContent htmlString={data.content} />,
                <CKEditor
                  editor={ClassicEditor}
                  data={data.content}
                  config={{
                    toolbar: [
                      "undo",
                      "redo",
                      "|",
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "|",
                      "link",
                      "insertTable",
                      "mediaEmbed",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                  }}
                  // onReady={(editor) => {
                  //   // You can store the "editor" and use when it is needed.
                  //   console.log("Editor is ready to use!", editor);
                  // }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    form.setFieldsValue({ content: data });
                  }}
                  // onBlur={ ( event, editor ) => {
                  //     console.log( 'Blur.', editor );
                  // } }
                  // onFocus={ ( event, editor ) => {
                  //     console.log( 'Focus.', editor );
                  // } }
                />
              )}
            </FormColInput2>
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
