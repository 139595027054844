import { Col, Row, Space } from "antd";

export interface TwoBlocksRowProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const TwoBlocksRow: React.FC<TwoBlocksRowProps> = ({ left, right }) => (
  <Row
    gutter={[16, 16]}
    style={{ marginBottom: 16, justifyContent: "space-between" }}
  >
    <Col xs={24} sm={12} lg={8}>
      {left}
    </Col>
    <Col
      xs={24}
      sm={12}
      lg={16}
      style={{ display: "flex", justifyContent: "right" }}
    >
      <Space>{right}</Space>
    </Col>
  </Row>
);
