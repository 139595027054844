import { api } from "api";
import {
  CheckOutlined,
  FileTextOutlined,
  DollarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { AuctionHammerIcon } from "components/Icons/AuctionHammerIcon";
import { GenerateStatuses } from "./stateStepUtils";

const iconStyle = { paddingRight: "6px" };

export const AuctionState = GenerateStatuses({
  endpoint: api.auction,
  options: [
    {
      value: "draft",
      label: "Draft",
      icon: <FileTextOutlined style={{ ...iconStyle, color: "grey" }} />,
    },
    {
      value: "scheduled",
      label: "Scheduled",
      icon: <ClockCircleOutlined style={{ ...iconStyle, color: "orange" }} />,
    },
    {
      value: "active",
      label: "Active",
      icon: <DollarOutlined style={{ ...iconStyle, color: "green" }} />,
    },
    {
      value: "closed",
      label: "Closed",
      icon: <CheckOutlined style={{ ...iconStyle, color: "blue" }} />,
      availableActions: [],
    },
  ],
});
