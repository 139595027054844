import { PlusOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { useNavigate } from "react-router-dom";

export interface Props extends ButtonProps {
  link: string;
  size?: "middle" | "small";
  extraProps?: any;
}

export const ButtonCreateNavigate = ({
  link,
  children,
  size,
  ...props
}: Props) => {
  const navigate = useNavigate();

  return (
    <Button
      size={size}
      onClick={() => void navigate(link)}
      icon={<PlusOutlined />}
    >
      {children && <span className={"responsive-button-text"}>{children}</span>}
    </Button>
  );
};
