import { Button, DatePicker, Flex, Input, Space } from "antd";
import { useAvailableFilterOptions } from "../SmartFilterDialog";
import { useState } from "react";
import { FilterOption } from "api";
import { ConfiguredSmartFilter } from "../useSmartFilters";
import dayjs from "dayjs";

export interface FilterProps {
  modelName: string;
  smartFilter: ConfiguredSmartFilter;
  handleChangeOperand: (operand: FilterOption) => void;
}

export const FilterRangePicker = ({
  modelName,
  smartFilter,
  handleChangeOperand,
}: FilterProps) => {
  const filterOptions = [
    // todo: de-hardcode
    {
      name: "Останній тиждень",
      id: "2023-11-27,2023-11-29",
    },
    {
      name: "Останній місяць",
      id: "2023-11-01,2023-11-29",
    },
  ];

  const handleDateChange = (dates: any) => {
    const from = dates?.[0];
    const to = dates?.[1];
    if (!from || !to) return;
    const range = [from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")].join(
      ","
    );
    const name = [from.format("DD/MM/YYYY"), to.format("DD/MM/YYYY")].join(
      " - "
    );
    handleChangeOperand({
      id: range,
      name,
    });
  };

  const getInitial = (): [dayjs.Dayjs, dayjs.Dayjs] | undefined => {
    if (!smartFilter?.operand?.id) return undefined;
    try {
      const [fromStr, toStr] = smartFilter.operand.id.split(",");
      return [dayjs(fromStr), dayjs(toStr)];
    } catch (e) {
      return undefined;
    }
  };

  const initial = getInitial();

  return (
    <Flex vertical gap="small">
      <Space.Compact>
        <DatePicker.RangePicker
          onChange={handleDateChange}
          defaultValue={initial}
        />
      </Space.Compact>
      <Flex vertical>
        {filterOptions.map((fo) => (
          <Button
            key={fo.id}
            type="text"
            onClick={() => {
              handleChangeOperand(fo);
            }}
            style={{ textAlign: "left" }}
          >
            {fo.name}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
