import { Card } from "antd";
import { Contract, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { LinkToAuction } from "components/Links/LinkToAuction";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";

export const ContractListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/contracts/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Contracts"
        actions={
          <ButtonCreateNavigate link="/platform/contracts/create">
            Contract
          </ButtonCreateNavigate>
        }
      />
      <DataTable<Contract>
        endpoint={api.contract}
        columns={columns}
        tableId={"ContractsListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Auction",
    dataIndex: "auction",
    key: "auction",
    sorter: true,
    sortIndex: "auction.name",
    render: (auc: any) => <LinkToAuction {...auc} />,
  },
  {
    title: "Author",
    dataIndex: "author",
    key: "author",
    sortIndex: "author.name",
    sorter: true,
    render: (author: any) => author?.name,
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    sortIndex: "user.name",
    sorter: true,
    render: (author: any) => author?.name,
  },
];
